import axios from "axios"
import { useState, useEffect } from "react"
import ModalImage from "react-modal-image";
import { Tooltip } from "react-tooltip";
import ImageTagsDetails from "../SocialTrendsTags/ImageTagsDetails";
import Collection from "./Collection";

const TrendImagesType = ({ trendType, imagesSort, searchParams, tag, value, page, setCount, setBrandCounts, setLoading, selectedBrand }) => {
  const [images, setImages] = useState([])
  const [allCollectionNames, setAllCollectionNames] = useState([])
  const [collectionSets, setCollectionSets] = useState({})
  const limit = 100
  const [localImageCount, setLocalImageCount] = useState(1)

  const getCollectionNames = async () => {
    const resp = await axios.get(`/api/saved-images/collections`)
    setAllCollectionNames(resp.data.collections)
    let collectionSetsTemp = {}
    resp.data.collections.forEach(coll => {
      collectionSetsTemp[coll._id] = new Set(coll[trendType === 'social' ? 'savedImages' : 'savedProducts']);
    })
    setCollectionSets(collectionSetsTemp)
  }


  const addImageToCollection = async (image, collectionId) => {
    const body = {
      imageId: image._id,
      collectionId
    }
    if (collectionSets[collectionId] && collectionSets[collectionId].has(image._id)) {
      body.delete = true
    }
    await axios.post(`/api/saved-images/image/${trendType}`, body)
    getCollectionNames()
  }


  const getImages = async () => {
    setLoading(true)
    setImages([])
    const resp = await axios.get(`/social-trends-v2-api/leaderboard-trend-images/${trendType}?gender=${searchParams.get('gender') || 'Female'}&category=${searchParams.get('category').split('&').join('%26')}&geography=${searchParams.get('geography') || 'All'}&source=${searchParams.get('source') || ''}&time=${''/*searchParams.get('time') || '30 days'*/}&page=${page || 1}&limit=100${searchParams.get('tag') ? `&tag=${encodeURIComponent(searchParams.get('tag'))}` : ''}${searchParams.get('value') ? `&value=${encodeURIComponent(searchParams.get('value'))}` : ''}&trendTag=${encodeURIComponent(tag)}&trendValue=${encodeURIComponent(value)}${trendType === 'retailer' && selectedBrand != null ? `&brand=${encodeURIComponent(selectedBrand)}` : ''}&sort=${'recency'}`)
    setLoading(false)
    setCount(prev => Math.max(prev, resp.data.count))
    setLocalImageCount(resp.data.count)
    setImages(resp.data.images)
    if (trendType === 'retailer') {
      setBrandCounts(resp.data.brandCounts)
    }
  }
  useEffect(() => {
    getImages()
  }, [searchParams, tag, value, page, selectedBrand, imagesSort])

  useEffect(() => {
    getCollectionNames()
  }, [])

  return <div className="w-[49%] shrink-0">
    <div className='m-2 justify-center flex'><img src={trendType == 'social' ? '/social-logo.png' : '/retailer-logo.png'} className='h-5' /></div>
    <div className="w-full flex flex-wrap my-2">
      {images.map(image => <div className="w-1/3 p-2 border border-slate-300">
        <div className="flex justify-center items-center relative group">
          <div className="relative group " data-tooltip-id={'trends-tooltip-' + (trendType == 'social' ? image.mediaId : image.pk)}>
            <Collection image={image} collections={allCollectionNames} fetchCollections={getCollectionNames} addImageToCollection={addImageToCollection} collectionSets={collectionSets} />
            <ModalImage
              small={image[trendType == 'social' ? 'imageUrl' : 'image_url']}
              large={image[trendType == 'social' ? 'imageUrl' : 'image_url']}
              className='h-48 w-auto'
            />
            <Tooltip id={'trends-tooltip-' + (trendType == 'social' ? image.mediaId : image.pk)} style={{ zIndex: 1100 }}>
              <div className="text-[10px] font-light">
                <h3 className="text-center">Trends of image</h3>
                {image.trends.map((catTrends, i) => <div className="my-2">
                  <div className="flex justify-between py-1 text-xs">
                    <h4>{i + 1}. {catTrends.category}</h4>
                    <div className="flex">
                      {trendType == 'social' ? <div className="px-2 ">{catTrends.aggregateRateOfGrowth} Sum ROG(social)</div> : <>
                        <div className="px-2 text-yellow-600 w-20 text-wrap shrink-0">{catTrends.socialAggregateRateOfGrowth} Sum ROG(social)</div>
                        {false ? <div className="px-2 w-20 shrink-0 text-wrap">{catTrends.aggregateRateOfGrowth} Sum ROG(retailer)</div> : null}
                      </>}
                      <h3>{catTrends.success} Success points </h3>
                    </div>
                  </div>
                  <div className="ml-4">

                    {
                      catTrends.trends.sort((a, b) => b.success - a.success).map(sil => <div key={sil.silhouette}>
                        <div className="flex justify-between w-full pr-2 mb-1 mt-3 text-xs">
                          <div>{sil.silhouette}</div>
                          <div className="flex">
                            {trendType == 'social' ? <div className="px-2 ">{sil.aggregateRateOfGrowth} Sum ROG(social)</div> : <>
                              <div className="px-2 text-yellow-600 w-20 text-wrap shrink-0">{sil.socialAggregateRateOfGrowth} Sum ROG(social)</div>
                              {false ? <div className="px-2 w-20 shrink-0 text-wrap">{sil.aggregateRateOfGrowth} Sum ROG(retailer)</div> : null}
                            </>}
                            <div>{sil.success} Success points</div>
                          </div>
                        </div>

                        {sil.trends.map(trend => <div className="flex justify-between px-2">
                          <p className="text-wrap pr-2">{trend.primaryTagValue} <span className="font-semibold">x</span> {trend.secondaryValue}</p>
                          <div className="flex">
                            {trendType == 'social' ? <p className="px-2">{Math.ceil(trend.rateOfGrowth * 1000) / 10}%</p> : <>
                              <p className="px-2 text-yellow-600">{Math.ceil(trend.socialrateOfGrowth * 1000) / 10}%</p>
                              {false ? <p className="px-2">{Math.ceil(trend.rateOfGrowth * 1000) / 10}%</p> : null}
                            </>
                            }
                            <p>{trend.lifecycle}</p>
                          </div>
                        </div>)}
                      </div>)
                    }
                  </div>
                </div>)}

              </div>
            </Tooltip>
          </div>
        </div>
        {trendType == 'social' ? <div className="px-3 text-xs">
          <a href={`${image.postUrl}`} className='text-blue-600 font-medium' target='_blank'>{image.username}</a>
          <p>{new Date(image.timeStamp).toDateString()}</p>
          <ImageTagsDetails mediaId={image.mediaId} />
        </div> : <div className="px-3 text-xs">
          <a href={`${image.product_url}`} className='text-blue-600 font-medium' target='_blank'>{image.retailer}</a>
          <p>Brand : {image.brand}</p>
          <p className="w-full text-nowrap overflow-hidden text-[11px]">{image.title}</p>
          <ImageTagsDetails mediaId={image.pk} isretailer={true} />
        </div>}

      </div>)}
    </div>


  </div>
}

export default TrendImagesType
