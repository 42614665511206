import { useEffect, useState } from 'react';
import { FaBookmark, FaPlus, FaRegBookmark } from 'react-icons/fa';
import { IoIosCheckmarkCircle } from 'react-icons/io'
import NewCollection from '../pages/V2Leaderboard/NewCollection';
import Popup from './Popup';
import useMixpanelTrack from '../hooks/useMixpanelTracking';
//import NewCollection from './NewCollection';
const AllImagesSaveButtonToCollection = ({ collections, addImageToCollection, fetchCollections }) => {

  const [showCollections, setShowCollections] = useState(false)
  const [showNewCollection, setShowNewCollection] = useState(false)
  const trackEvent = useMixpanelTrack()
  const addImageToCollectionById = async (e) => {
    addImageToCollection(e.target.id)
    trackEvent('Bulk images added to existing collection', {})
  }
  const addImageToNewCollection = (image, collectionId) => {
    addImageToCollection(collectionId)
    trackEvent('Bulk images added to new collection', {})
  }
  //invisible group-hover:visible
  return <><div className="relative  ">
    <div className={`font-semibold cursor-pointer text-[14px] border rounded-xl px-4 bg-slate-500 hover:bg-slate-700 active:bg-slate-800 text-white py-1 `}
      onMouseEnter={() => { setShowCollections(true); }}
      onMouseLeave={() => { setShowCollections(false); }}
    >Add all images to collection
      {
        showCollections
          ? <div className='absolute  bg-slate-700/90  py-3 left-1/2 -translate-x-1/2 z-30 rounded min-w-80 cursor-auto max-h-[600px] overflow-y-auto text-white'>

            <div className='flex justify-between px-2 mb-2 py-1 '>
              <button className='ml-4 flex'
                onClick={() => setShowNewCollection(true)}
              >
                <span className='mr-4'>Add New Collection</span>
                <FaPlus color='white' size={14} />
              </button>
            </div>
            {
              collections.map(c => <button id={c._id} onClick={addImageToCollectionById} className={`w-full py-2 text-left pl-2  bg-slate-700 hover:bg-slate-600 flex justify-between pr-4`} >{c.collectionName}  </button>)
            }
          </div>

          : null}
    </div>
  </div>
    <NewCollection show={showNewCollection} setIsPopUp={setShowNewCollection} addImageToCollection={addImageToNewCollection} fetchCollections={fetchCollections} collections={collections} />

  </>

}

export default AllImagesSaveButtonToCollection
