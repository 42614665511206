import axios from "axios"
import { useEffect, useState } from "react"
import ModalImage from "react-modal-image";
import { useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingWrapper from "../../component/LoadingWrapper";
import ImageTagsDetails from "../SocialTrendsTags/ImageTagsDetails";
import SaveUnsaveButton from "./SaveUnsaveButton";

const SavedImages = () => {

  const { Id } = useParams()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [socialImages, setSocialImages] = useState([])
  const [retailerImages, setRetailerImages] = useState([])
  const [webImages, setWebImages] = useState([])
  const [totalImages, setTotalImages] = useState(1)
  const [collectionName, setCollectionName] = useState('')
  const [collectionNotFound, setCollectionNotFound] = useState(false)
  const [tab, setTab] = useState('WEB');
  const [deletedImageIds, setDeletedImageIds] = useState(new Set([]))
  const [isOwner, setIsOwner] = useState(false)
  const [description, setDescription] = useState('')
  const [newDescription, setNewDescription] = useState('')
  const [isUpdateDescription, setIsUpdateDescription] = useState(false)

  const getCollectionImages = async () => {
    setLoading(true)
    const resp = await axios.get(`/api/saved-images/collection-images/${Id}?page=${page}`)
    setLoading(false)
    if (!resp.data.collectionFound) {
      setCollectionNotFound(true)
    } else {
      setSocialImages(resp.data.socialimages)
      setCollectionName(resp.data.collectionName)
      setRetailerImages(resp.data.retailerImages)
      setTotalImages(resp.data.count)
      setIsOwner(resp.data.isOwner)
      setDescription(resp.data.description)
    }
  }

  const updateSavedImageStatus = async (imageId, imageType) => {
    const body = {
      imageId: imageId,
      collectionId: Id
    }
    if (!deletedImageIds.has(imageId)) {
      body.delete = true
    } else {
    }
    const resp = await axios.post(`/api/saved-images/image/${imageType}`, body)
    if (resp.data.success) {
      const newIds = new Set([...deletedImageIds.values()])
      if (!newIds.has(imageId)) {
        newIds.add(imageId)
      } else {
        newIds.delete(imageId)
      }
      setDeletedImageIds(newIds)
    }
  }
  const updateSocialImage = (e) => {
    updateSavedImageStatus(e.target.id, 'social')
  }
  const updateRetailerImage = (e) => {
    updateSavedImageStatus(e.target.id, 'retailer')
  }
  const updateWebImage = (e) => {
    updateSavedImageStatus(e.target.id, 'web')
  }


  const getWebCollectionImages = async () => {
    setLoading(true)
    const resp = await axios.get(`/api/saved-images/webimages/${Id}`)
    setLoading(false)
    setWebImages(resp.data.webImages)
  }

  const updateCollectionDescription = async () => {
    const resp = await axios.patch(`/api/saved-images/collection?collectionId=${Id}`, { description: newDescription })
    if (resp.data == 'ok') {
      console.log('success')
      setDescription(newDescription)
    }
    setNewDescription('')
    setIsUpdateDescription(false)
  }

  useEffect(() => {
    getCollectionImages()
  }, [page])

  useEffect(() => {
    getWebCollectionImages()
  }, [])
  const downloadCsv = () => {
    const textToSaveArray = ['Image Type,Image Url,Date (Saved/IG/insert),Source(Website Name/Blogger/Retailer),Link,Metadata Link,Product title']
    webImages.forEach(image => {
      textToSaveArray.push(`"Web","${image.image_url}","${new Date(image.timeStamp).toDateString()}","${image.website_domain}",,,`)
    })
    socialImages.forEach(image => {
      textToSaveArray.push(`"Social","${image.imageUrl}","${new Date(image.timeStamp).toDateString()}","${image.username}","${image.postUrl}","${window.location.origin}/image-metadata/${image.mediaId}",`)
    })
    retailerImages.forEach(image => {
      textToSaveArray.push(`"Retailer","${image.image_url}","${new Date(image.insertDate).toDateString()}","${image.retailer}","${image.product_url}","${window.location.origin}/product-metadata/${image.pk}","${image.title}"`)
    })
    const blob = new Blob([textToSaveArray.join('\n')], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url
    a.download = `${collectionName} Images.csv`;
    a.click()

  }

  return <div className="flex ">

    <div className="w-full min-h-[80vh]">
      {
        collectionNotFound ? <div className="flex w-full h-full justify-center items-center">
          <div className="font-semibold">No Collection Found</div>

        </div> : <>
          <h3 className="text-center text-[26px] my-3 mt-8">{`${collectionName} Collection Images`.toUpperCase()}</h3>
          <div className="flex px-20 my-2 justify-between">
            {
              isUpdateDescription ?
                <div className="w-full flex ">
                  <div className=" flex w-full">

                    <div className="w-1/2 "></div>
                    <textarea
                      value={newDescription}
                      onChange={e => setNewDescription(e.target.value)}
                      className='w-1/2 border h-full m-2 rounded-lg p-4 font-medium italic border-slate-300 text-sm'
                    />
                  </div>
                  <div className="flex h-full justify-start flex-col">
                    <button onClick={updateCollectionDescription} className='primary-btn my-2'>Save</button>
                    <button onClick={() => {
                      setIsUpdateDescription(false)
                    }}
                      className='primary-btn my-2'
                    >Cancel</button>
                  </div>
                </div>
                : <div className="flex w-full">
                  <p className="w-1/2 shrink-0"></p>
                  <p className="text-sm font-medium italic w-1/2 shrink-0 pl-16" >{description ? description : ''}</p>
                </div>
            }
            {isOwner && !isUpdateDescription ? <button className="primary-btn" onClick={() => {
              setNewDescription(description)
              setIsUpdateDescription(true)
            }}>{description ? 'Change description' : 'Add Description'}</button> : null}
          </div>

          <LoadingWrapper loading={loading}>
            <div className="flex justify-start my-1 pl-6">
              <button className="primary-btn mx-1" onClick={downloadCsv}>Download Excel</button>
              <button className="primary-btn mx-1" onClick={(e) => {
                window.navigator.clipboard.writeText(`${window.location.host}/saved-images/${Id}`)
                Swal.fire({ 'icon': 'success', text: 'Collection URL copied to clipboard' })
              }}>Share</button>
            </div>

            <hr className="mt-2 mb-4" />
            <div className="flex">
              <div className="w-1/3 border-r border-black h-full px-2 shrink-0">
                <h4 className="text-center font-medium text-xl">Web Images</h4>
                <div className="flex justify-center">
                  <hr className="w-3/4" />
                </div>
                <div className="flex flex-wrap ">
                  {webImages.map(im => <div key={im._id} className="w-1/3  shrink-0 px-1 m-2 border py-2">
                    <div className="flex justify-center mb-3 relative">
                      <ModalImage small={im.image_url}
                        large={im.image_url}
                        className="h-40"
                      />
                      <SaveUnsaveButton key={`${im._id}-${deletedImageIds.has(im._id)}`} imageId={im._id} deletedIds={deletedImageIds} onClickHandler={updateWebImage} />
                    </div>
                    <p className="text-[10px] font-semibold ">Saved on : {new Date(im.timeStamp).toLocaleString()}</p>
                    <p className="text-[10px] font-semibold ">Saved from: {im.website_domain}</p>

                  </div>)}

                </div>
              </div>

              <div className="w-1/3 border-r border-black h-full px-2 shrink-0">
                <h4 className="text-center font-medium text-xl">Social Images</h4>
                <div className="flex justify-center">
                  <hr className="w-3/4" />
                </div>
                <div className="flex flex-wrap w-full mt-2">
                  {socialImages.map(img => <div key={img._id} className="w-1/3 px-1 border py-2">
                    <div className="flex justify-center relative">
                      <ModalImage small={img.imageUrl}
                        large={img.imageUrl}
                        className="h-40"
                      />
                      <SaveUnsaveButton key={`${img._id}-${deletedImageIds.has(img._id)}`} imageId={img._id} deletedIds={deletedImageIds} onClickHandler={updateSocialImage} />
                    </div>
                    <div className="px-2 text-sm">
                      <a href={`${img.postUrl}`} className='text-blue-600 font-medium' target='_blank'>{img.username}</a>
                      <p>{new Date(img.timeStamp).toDateString()}</p>
                      <div></div>
                    </div>

                    <div className="flex text-sm my-2 justify-between px-2">
                      <ImageTagsDetails mediaId={img.mediaId} />

                    </div>


                  </div>)}
                </div>
                <div className="flex w-full h-full py-40">
                  {socialImages.length == 0 ? <p className="text-center text-sm w-full underline underline-offset-4">End of results</p> : null}
                </div>

              </div>

              <div className="w-1/3 border-r border-black h-full px-2 shrink-0">
                <h4 className="text-center font-medium text-xl">Retailer Images</h4>
                <div className="flex justify-center">
                  <hr className="w-3/4" />
                </div>
                <div className="flex flex-wrap w-full">
                  {retailerImages.map(img => <div key={img._id} className="w-1/3 px-1 my-2 border">
                    <div className="flex justify-center relative">

                      <ModalImage small={img.image_url}
                        large={img.image_url}
                        className="h-40"
                      />
                      <SaveUnsaveButton key={`${img._id}-${deletedImageIds.has(img._id)}`} imageId={img._id} deletedIds={deletedImageIds} onClickHandler={updateRetailerImage} />
                    </div>
                    <div className="px-3">
                      <a href={`${img.product_url}`} className='text-blue-600 font-medium' target='_blank'>{img.retailer}</a>
                      <p>Brand : {img.brand}</p>
                    </div>

                    <div className="flex text-sm my-2 justify-between px-2">
                      <ImageTagsDetails mediaId={img.pk} isretailer={true} />

                    </div>

                  </div>)}
                </div>
                <div className="flex w-full h-full py-40">
                  {retailerImages.length == 0 ? <p className="text-center text-sm w-full underline underline-offset-4">End of results</p> : null}
                </div>
              </div>
            </div>

          </LoadingWrapper>

        </>
      }
    </div>


  </div >
}

export default SavedImages
