import axios from "axios"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Swal from "sweetalert2"
import Loadingwrapper from "../../component/LoadingWrapper"


const SavedImagesCollections = () => {


  const [collections, setCollections] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)

  const getCollections = async () => {
    setLoading(true)
    const resp = await axios.get(`/api/saved-images/collections`)
    setCollections(prev => {
      const next = resp.data.collections
      next.sort((a, b) => {
        if (searchParams.get('sort') == 'alphabetic')
          return a.collectionName > b.collectionName ? 1 : -1
        else if (searchParams.get('sort') == 'recency')
          return new Date(b.lastUpdated) > new Date(a.lastUpdated)
        else return b.totalImages - a.totalImages
      })
      return next;
    })
    setLoading(false)
  }


  const handleCollectionActions = async (collection, action) => {
    if (action == 'SHARE') {
      window.navigator.clipboard.writeText(`${window.location.host}/saved-images/${collection._id}`)
      Swal.fire({ 'icon': 'success', text: 'Collection URL copied to clipboard' })
    } else if (action == 'DUPLICATE') {
      const SwalResponse = await Swal.fire({ title: "Do you want to duplicate " + collection.collectionName + " collection?", showDenyButton: true, showConfirmButton: true, denyButtonText: "Cancel", confirmButtonText: "DUPLICATE" })
      if (SwalResponse.isConfirmed) {
        const resp = await axios.post(`/api/saved-images/duplicate/collection?collectionId=${collection._id}`, {})
        getCollections()
      }
    } else if (action == 'DELETE') {
      const SwalResponse = await Swal.fire({ title: "Do you want to delete " + collection.collectionName + " collection?", showDenyButton: true, showConfirmButton: true, denyButtonText: "Cancel", confirmButtonText: "DELETE" })
      if (SwalResponse.isConfirmed) {
        const resp = await axios.delete(`/api/saved-images/collection?collectionId=${collection._id}`)
        getCollections()
      }
    } else if (action == 'RENAME') {

      const SwalResponse = await Swal.fire({ title: "Do you want to rename " + collection.collectionName + " collection?", showDenyButton: true, showConfirmButton: true, denyButtonText: "Cancel", confirmButtonText: "RENAME", input: "text", inputPlaceholder: "Collection Name" })
      if (SwalResponse.isConfirmed) {

        const resp = await axios.patch(`/api/saved-images/collection?collectionId=${collection._id}`, { name: SwalResponse.value })
        getCollections()
      }
    }


  }

  useEffect(() => {
    getCollections()
  }, [])

  const sortCollections = () => {
    setCollections(prev => {
      let next = [...prev].sort((a, b) => {
        if (searchParams.get('sort') == 'alphabetic')
          return a.collectionName > b.collectionName ? 1 : -1
        else if (searchParams.get('sort') == 'recency')
          return new Date(b.lastUpdated) > new Date(a.lastUpdated) ? 1 : -1
        else return b.totalImages - a.totalImages
      })
      return next;
    })
  }
  useEffect(() => {
    sortCollections()
  }, [searchParams.get('sort')])
  return <div>
    <div className="flex justify-between px-16 py-8">
      <h2 className="text-xl font-semibold">My Collections</h2>
      <div className="flex ">
        <select value={searchParams.get('sort')} onChange={e => setSearchParams(prev => {
          prev.set('sort', e.target.value)
          return prev
        })}
          className="px-2 py-1 mr-8"
        >
          <option value={'recency'}>Recently updated</option>
          <option value={'count'}>Most number of images</option>
          <option value={'alphabetic'}>Alphabetic</option>
        </select>
      </div>
    </div>

    <Loadingwrapper loading={loading}>
      <p className="px-6">Showing {collections.length} collections</p>
      <div className="flex px-4 flex-wrap w-full justify-left mb-20 ">
        {collections.map(collection => <div key={collection._id} className="w-[23%] shrink-0 h-[38rem] rounded-lg border-2 m-2 my-4 hover:scale-105 transition-transform"> <a href={`/saved-images/${collection._id}`} target="_blank" >
          <h3 className="mt-8 text-center font-semibold">Collection : {collection.collectionName}</h3>
          <div className="flex flex-wrap h-[21rem] px-4 justify-left mt-4">
            {
              collection.thumbnails.slice(0, 4).map(img => <img key={img} src={img} className="h-40 w-[46%] shrink-0 m-0.5" alt="Saved Image" />)
            }

          </div>
          <div className="flex justify-center">

            <hr className="w-3/4 border-slate-200 my-2" />
          </div>
          <div className="flex justify-end px-4">
            {false ? <p className="text-center text-xs font-normal mb-2">Web Images : {collection.savedWebImagesLength}</p> : null}
            <p className="text-center text-xs font-normal mb-2">Total Images : {collection.totalImages}</p>
          </div>

          <p className="text-center text-xs font-normal mb-2">Last updated: {collection.lastUpdated ? new Date(collection.lastUpdated).toLocaleString() : ''}</p>

        </a>
          <div className="flex flex-wrap px-2 justify-center" onClick={e => { e.stopPropagation(); }}>
            <button className="w-2/5 shrink-0 rounded bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800 m-1" onClick={(e) => { e.stopPropagation(); handleCollectionActions(collection, 'RENAME'); }}>Rename</button>
            <button className="w-2/5 shrink-0 rounded bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800 m-1" onClick={(e) => { e.stopPropagation(); handleCollectionActions(collection, 'DELETE'); }}>Delete</button>
            <button className="w-2/5 shrink-0 rounded bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800 m-1" onClick={(e) => { e.stopPropagation(); handleCollectionActions(collection, 'DUPLICATE'); }}>Duplicate</button>
            <button className="w-2/5 shrink-0 rounded bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800 m-1" onClick={(e) => { e.stopPropagation(); handleCollectionActions(collection, 'SHARE'); }}>Share</button>
          </div>
        </div>)}
      </div>
    </Loadingwrapper>


  </div>
}

export default SavedImagesCollections;
