import { Tooltip } from "react-tooltip";

function DepthToolTip({ trend, level, category }) {



  return <Tooltip id={'depth-tooltip-' + level + trend.tag + '-' + trend.value} style={{ zIndex: 1100, opacity: 1 }} opacity={1}>
    <div className="text-[10px] font-light text-center bg-slate-800">
      <table>
        <thead>
          <tr >
            <th className="w-20 text-wrap">TimeFrame</th>
            <th className="w-20 text-wrap">Depth in {category}</th>
            <th className="w-40 text-wrap">Depth in {trend.tag}</th>
          </tr>
        </thead>
        <tbody>
          {['7', '15', '30', '60', '90'].map(t => <tr key={t}>
            <td>{t} days</td>
            <td className="text-[12px]">{trend[`cdcount${t}days`]}%</td>
            <td className="text-[12px]">{trend[`tdcount${t}days`]}%</td>
          </tr>)}
        </tbody>
      </table>

    </div>
  </Tooltip>
}

export default DepthToolTip;
