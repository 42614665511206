import { useSearchParams } from "react-router-dom"
import TrendImages from "../BuildDesigns/ImagePopup"

const V2LeaderboardImagesPage = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  console.log(searchParams)

  return <TrendImages
    searchParams={searchParams}
    tag={searchParams.get('tag')}
    value={searchParams.get('value')}
    isPage={true}
    level={searchParams.get('breakdown-level')}
  />
}

export default V2LeaderboardImagesPage
