import axios, { isAxiosError } from "axios"
import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Swal from "sweetalert2"
import DepthToolTip from "../../component/DepthTooltip"
import Loadingwrapper from "../../component/LoadingWrapper"
import TrendImages from "./ImagePopup"
import TagBlock from "./TagBlock"
import useMixpanelTrack from "../../hooks/useMixpanelTracking"

const BuildDesigns = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const [showGeography, setShowGeography] = useState(false)
  const [showSource, setShowSource] = useState(false)
  const [showTimeframe, setShowTimeframe] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedTags, setSelectedTags] = useState({});
  const [tagsData, setTagsData] = useState([])
  const previousTagValuePairs = useRef([])
  const selectedTagNames = useRef(new Set())
  const trackEvent = useMixpanelTrack()

  useEffect(() => {
    let prevBreakdownLevel = 0;
    const tagValuePairs = []
    trackEvent('Build Design Page Open', { gender: searchParams.get('gender'), category: searchParams.get('category') })
    setSearchParams(prev => {
      //      prev.set('time', prev.has('time') ? prev.get('time') : '15 days')
      //      prev.set('geography', prev.has('geography') ? prev.get('geography') : 'All')
      prev.set('source', prev.has('source') ? prev.get('source') : '')
      prev.set('sort', prev.has('sort') ? prev.get('sort') : 'rog')
      if (prev.has('breakdown-level') && !isNaN(parseInt(prev.get('breakdown-level')))) {
        prevBreakdownLevel = parseInt(prev.get('breakdown-level'));
      }
      for (let i = 0; i < prevBreakdownLevel; i++) {
        const tag = prev.get(`tag-${i}`)
        const value = prev.get(`value-${i}`)
        prev.delete(`tag-${i}`)
        prev.delete(`value-${i}`)
        tagValuePairs.push({ tag, value })
      }
      prev.set('breakdown-level', '0')
      return prev
    })
    if (tagValuePairs.length > 0) {

      previousTagValuePairs.current = tagValuePairs
    }
  }, [])

  useEffect(() => {
    selectedTagNames.current = new Set(Object.keys(selectedTags))
  }, [selectedTags])

  useEffect(() => {
    setSelectedTags({})
  }, [searchParams.get('breakdown-level')])

  const checkForExistingPreviousTagValuePairs = () => {
    if (previousTagValuePairs.current.length == 0) {
      return;
    }
    const { tag, value } = previousTagValuePairs.current[0]
    setSearchParams(prev => {
      const previousIndex = parseInt(prev.get('breakdown-level'))
      prev.set(`tag-${previousIndex}`, tag);
      prev.set(`value-${previousIndex}`, value)
      prev.set('breakdown-level', previousIndex + 1)
      previousTagValuePairs.current = previousTagValuePairs.current.slice(1)
      return prev;
    })
  }

  useEffect(() => {
    setSearchParams(prev => {
      const level = parseInt(prev.get('breakdown-level'));
      for (let i = level; i < 3; i++) {
        if (searchParams.has(`tag-${i}`)) {
          searchParams.delete(`tag-${i}`)
        }
        if (searchParams.has(`value-${i}`)) {
          searchParams.delete(`value-${i}`)
        }
      }
      return prev;
    })
  }, [searchParams.get('breakdown-level')])

  useEffect(() => {
    setSelectedTags({})
  }, [searchParams.get('tag-0'), searchParams.get('value-0'), searchParams.get('tag-1'), searchParams.get('value-1'),])

  const getTrends = async (signal) => {
    setLoading(true)
    try {
      let tagsQuery = ''
      const breakdownLevel = parseInt(searchParams.get('breakdown-level') || '0')
      for (let i = 0; i < breakdownLevel; i++) {
        tagsQuery = tagsQuery + `&tag-${i}=${encodeURIComponent(searchParams.get(`tag-${i}`))}&value-${i}=${encodeURIComponent(searchParams.get(`value-${i}`))}`
      }

      const resp = await axios.get(`/api/build-designs/trends?gender=${searchParams.get('gender') || 'Female'}&category=${searchParams.get('category').split('&').join('%26')}${searchParams.get('tag') ? `&tag=${encodeURIComponent(searchParams.get('tag'))}` : ''}${searchParams.get('value') ? `&value=${encodeURIComponent(searchParams.get('value'))}` : ''}&breakdown-level=${searchParams.get('breakdown-level')}${tagsQuery}`, { signal })
      setLoading(false)
      setTagsData(prev => {
        const breakdownLevel = parseInt(searchParams.get('breakdown-level') || '0')
        prev = prev.slice(0, breakdownLevel)
        prev.push(resp.data.trends)
        return prev
      })
      checkForExistingPreviousTagValuePairs()
    } catch (e) {
      if (!isAxiosError(e)) {
        console.log(e)
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      getTrends(signal)
    } catch (e) {
      console.error(e)
    }
    return () => {
      abortController.abort()
    }
  }, [searchParams])

  useEffect(() => {
    let tags = []
    let values = []
    for (let k in selectedTags) {
      tags.push(encodeURIComponent(k))
      values.push(encodeURIComponent(selectedTags[k]))
    }
    const tag = tags.join(',,')
    const value = values.join(',,')

    setSearchParams(prev => {
      prev.set('tag', tag)
      prev.set('value', value)
      return prev;
    })
  }, [selectedTags])


  return <div>
    <h2 className="text-center font-semibold text-xl my-2">{searchParams.get('gender')} {searchParams.get('category')} Design Builder</h2>
    <div className="py-2 hidden bg-gray-600 text-white justify-between">
      <div className="flex ">
        {false &&
          <div className="mx-6 w-52 relative " onMouseEnter={() => { setShowSource(true) }} onMouseLeave={() => setShowSource(false)}>
            <div className="w-full text-nowrap overflow-hidden">Channel : {!searchParams.get('source') || searchParams.get('source') == '' ? 'All' : `${searchParams.get('source').split(',')[0]}${searchParams.get('source').split(',').length > 1 ? `(+ ${searchParams.get('source').split(',').length - 1})` : ''} `}          </div>
            {showSource && <div className="absolute w-full left-0 py-2 bg-gray-600 text-[10px] text-wrap">

              {['All', 'Mega Influencers', 'Micro Influencers', 'Celebrities', 'Pages/Magazines', 'Stores/Brands']
                .map(t => <button
                  key={t}
                  className={`mx-2 ${(searchParams.get('source') || '').split(',').includes(t) || (searchParams.get('source') == '' && t == 'All') ? 'font-semibold underline' : ''} w-full text-left px-4 py-1`}
                  onClick={() => {
                    setSearchParams(prev => {
                      let newVal = prev.get('source');
                      if (t == 'All') {
                        newVal = ''
                      } else {
                        if (newVal.includes(t)) {
                          newVal = newVal.split(',').filter((item) => item != t).join(',')

                        } else {
                          newVal = newVal == '' ? t : newVal + ',' + t
                        }
                      }
                      if (newVal.split(',').length == 5) {
                        newVal = ''
                      }
                      prev.set('source', newVal);
                      return prev
                    })
                  }}
                >{t}</button>)}
            </div>}
          </div>
        }
        <div className="mx-6 relative" onMouseEnter={() => { setShowGeography(true) }} onMouseLeave={() => setShowGeography(false)}>

          <div className="w-full text-nowrap overflow-hidden">Geography: {searchParams.get('geography')} </div>
          {showGeography && <div className="absolute w-full left-0 py-2 bg-gray-600 text-[10px] text-wrap">

            {['All', 'Western', 'Asian', 'Indian'].map(t => <button
              key={t}
              className={`mx-2 ${(searchParams.get('geography') == t) ? 'font-semibold underline' : ''} w-full text-left px-4 py-1`}
              onClick={() => {
                setSearchParams(prev => {
                  prev.set('geography', t);
                  return prev
                })
              }}
            >{t}</button>)}
          </div>}

        </div>

        <div className="mx-6 relative" onMouseEnter={() => { setShowTimeframe(true) }} onMouseLeave={() => setShowTimeframe(false)}>

          <div className="w-full text-nowrap overflow-hidden">Trending in : Past {searchParams.get('time')} </div>

          {showTimeframe && <div className="absolute w-full left-0 py-2 bg-gray-600 text-[10px] text-wrap text-left">
            {['7 days', '15 days', '30 days', '60 days', '90 days', '180 days']
              .map(t => <button
                key={t}
                className='mx-2 disabled:font-semibold disabled:underline w-full'
                disabled={t == searchParams.get('time')}
                onClick={() => {
                  setSearchParams(prev => {
                    prev.set('time', t);
                    return prev
                  })
                }}
              >{t}</button>)}
          </div>}
        </div>
      </div>

    </div>
    <Loadingwrapper loading={loading} >
      <div className="flex justify-center">
        <div className="w-[99.5vw] flex mt-2 border-black border-2 border-b-0 bg-slate-300 ">
          <div className="w-[15%] border-r-4 border-black">
            <h3 className="w-full shrink-0 text-center font-semibold  border-r-4">Pick Trending Attribute</h3>
          </div>
          <div className="w-[32.5%] border-r-4 border-black">
            <h3 className="w-full shrink-0 text-center my-1 font-semibold">Social Images</h3>
          </div>
          <div className="w-[32.5%] border-r-4 border-black">
            <h3 className="w-full shrink-0 text-center my-1 font-semibold">Retailer Images</h3>
          </div>
          <div className="w-[12.5%] border-r-4 border-black">
            <h3 className="w-full shrink-0 text-center my-1 font-semibold">Select a Silhouette</h3>
          </div>
          <div className="w-[7.5%] border-r-4 border-black">
            <h3 className="w-full shrink-0 text-center my-1 font-semibold">Optional Attribute(s)</h3>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-[99.5vw] flex flex-wrap  border-black border-2">
          <div className="w-[15%]">
            {tagsData[0] ? tagsData[0].map((trend, index) => <DepthToolTip trend={trend} level={0} category={searchParams.get('category')} />) : null}
            <div className="border h-[84vh] flex flex-wrap overflow-y-auto pb-12 shrink-0 border-r-black border-r-4">

              {tagsData[0] ? tagsData[0].map((trend, index) => ({ trend, index })).sort((a, b) => {
                if (a.trend.tag == searchParams.get('tag-0') && a.trend.value == searchParams.get('value-0')) return -1
                if (b.trend.tag == searchParams.get('tag-0') && b.trend.value == searchParams.get('value-0')) return 1
                return a.index - b.index
              }).map(({ trend, index }) => <TagBlock key={index} trend={trend} index={index} searchParams={searchParams} setSearchParams={setSearchParams} level={0} isBlockSelected={searchParams.get('tag-0') == trend.tag && searchParams.get('value-0') == trend.value} />) : null}
            </div>
          </div>
          <div className="w-[65%] border-r-4 border-r-black ">
            {
              !searchParams.has('tag-0') ? <div className="flex w-full  justify-center items-center"> <p className="w-1/2 text-center">Please select a trending attribute to display images.</p></div>
                : <>
                  <div className="border  h-[87vh] flex flex-col overflow-y-auto pb-12 shrink-0 overflow-visible ">
                    <TrendImages isPage={true} level={parseInt(searchParams.get('breakdown-level') || '0')} tag={searchParams.get('tag')} value={searchParams.get('value')} searchParams={searchParams} isMainPage={true} />
                  </div>
                </>
            }
          </div>
          <div className="w-[12.5%] ">
            {tagsData[1] ? tagsData[1].sort((a, b) => {
              return b.tdcount7days - a.tdcount7days
            }).map((trend, index) => <DepthToolTip trend={trend} level={1} category={searchParams.get('category')} />) : null}
            <div className="border h-[87vh] flex flex-col pb-12 shrink-0 border-r-black border-r-4 overflow-x-visible overflow-y-auto">
              {tagsData[1] ? tagsData[1].filter(trend => trend.rateOfGrowth > 0 || trend.tdcount7days > 0).map((trend, index) => ({ trend, index })).sort((a, b) => {
                if (a.trend.tag == searchParams.get('tag-1') && a.trend.value == searchParams.get('value-1')) return -1
                if (b.trend.tag == searchParams.get('tag-1') && b.trend.value == searchParams.get('value-1')) return 1
                return a.index - b.index
              }).map(({ trend, index }) => <TagBlock key={index} trend={trend} index={index} searchParams={searchParams} setSearchParams={setSearchParams} level={1} isBlockSelected={searchParams.get('tag-1') == trend.tag && searchParams.get('value-1') == trend.value} />) : null}
            </div>
          </div>
          <div className="w-[7.5%] ">
            <div className="border h-[87vh] flex flex-col overflow-y-auto pb-12 shrink-0 border-r-black border-r-4">
              {tagsData[2] ? tagsData[2].map((trend, index) => ({ trend, index })).sort((a, b) => {
                if (selectedTags[a.trend.tag] && selectedTags[a.trend.tag] == a.trend.value) {
                  if (selectedTags[b.trend.tag] && selectedTags[b.trend.tag] == b.trend.value) return a.index - b.index
                  return -1
                }
                if (selectedTags[b.trend.tag] && selectedTags[b.trend.tag] == b.trend.value) {
                  return 1
                }
                return a.index - b.index
              }).map(({ trend, index }) => <TagBlock key={index} trend={trend} index={index} searchParams={searchParams} setSearchParams={setSearchParams} level={2} selectedTags={selectedTags} setSelectedTags={setSelectedTags} selectedTagNames={selectedTagNames} isBlockSelected={selectedTags[trend.tag] == trend.value} />) : null}
            </div>
          </div>
        </div>

      </div >
    </Loadingwrapper >
  </div >
}

export default BuildDesigns
