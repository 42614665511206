import { useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';
import DepthToolTip from '../../component/DepthTooltip';
import Popup from '../../component/Popup';
import TrendImages from './ImagePopup';
import useMixpanelTrack from '../../hooks/useMixpanelTracking';

const TagBlock = ({ trend, index, searchParams, setSearchParams, level, selectedTags, isBlockSelected, setSelectedTags }) => {
  const trackEvent = useMixpanelTrack()
  const [showImagesPopup, setShowImagespopup] = useState(false)
  const selectTagHandler = (e) => {
    if (level != 2) {
      setSearchParams(prev => {
        if (prev.get(`tag-${level}`) == `${trend.tag}` && prev.get(`value-${level}`) == `${trend.value}`) {
          prev.set('breakdown-level', level);
          prev.delete(`tag-${level}`)
          prev.delete(`value-${level}`)
          trackEvent('Unselected Tag', { category: searchParams.get('category'), level, tag: trend.tag, value: trend.value })
        } else {
          trackEvent('Selected Tag', { category: searchParams.get('category'), level, tag: trend.tag, value: trend.value })
          prev.set('breakdown-level', level + 1);
          prev.set(`tag-${level}`, `${trend.tag}`)
          prev.set(`value-${level}`, `${trend.value}`)
        }
        return prev;
      })
    } else {
      setSelectedTags(prev => {
        const next = { ...prev }
        if (next[trend.tag]) {
          delete next[trend.tag]
          trackEvent('Unselected Tag', { category: searchParams.get('category'), level, tag: trend.tag, value: trend.value })
        } else {
          trackEvent('Selected Tag', { category: searchParams.get('category'), level, tag: trend.tag, value: trend.value })
          next[trend.tag] = trend.value
        }
        return next;
      })
    }
    e.stopPropagation()
  }

  return <div className={`${level == 0 ? "w-full" : "w-full"} h-24 border shrink-0 relative group `}>
    <button
      className={`w-full cursor-pointer flex h-full disabled:cursor-not-allowed disabled:grayscale disabled:bg-slate-200 ${isBlockSelected ? 'top-40 bg-slate-300 border-slate-700 border-2' : ''}`}
      onClick={selectTagHandler}
      disabled={selectedTags && selectedTags[trend.tag] && selectedTags[trend.tag] != trend.value}
    >
      <div className={`${level == 0 ? 'w-2/3' : 'w-full'} flex flex-col justify-center h-full pl-4`}>
        <div className="font-semibold text-xs">{index + 1}. {trend.value} {trend.tag}</div>
        {level < 2 ? <div className="text-[9px] pl-2 mt-0.5  text-[#808080] italic" data-tooltip-id={'depth-tooltip-' + level + trend.tag + '-' + trend.value}>7-Day Depth {trend.tdcount7days}%</div> : null}
        {level < 2 ? <div className="text-[11px] pl-2  text-black font-semibold italic">Social ROG {parseInt(trend.rateOfGrowth * 1000) / 10}%</div> : null}
      </div>
      {level == 0 ? <div className="w-1/3 h-full flex justify-center overflow-hidden">
        {trend.images.slice(0, 3).map(image => <img src={image.imageUrl} className='h-full w-14 mx-1 shrink-0' />)}
      </div> : null}
    </button>
    {false ? <button className="absolute z-10 right-0 w-6 top-[12.5%] h-3/4  bg-gray-100 -translate-x-1 hidden group-hover:block"
      data-tooltip-id={`select-button-${level}-${trend.tag}-${trend.value}`}
      onClick={selectTagHandler}>
      <BsChevronRight color='gray' size={25} strokeWidth={1} />
      <Tooltip id={`select-button-${level}-${trend.tag}-${trend.value}`}>select attribute      </Tooltip>

    </button>
      : null}
    {
      showImagesPopup ? <Popup isPopUp={showImagesPopup} setIsPopUp={setShowImagespopup}>
        <TrendImages level={level} tag={trend.tag} value={trend.value} searchParams={searchParams} />
      </Popup> : null
    }
  </div >
}

export default TagBlock
