import axios, { isAxiosError } from "axios"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import Loadingwrapper from "../../component/LoadingWrapper"
import RogSuccessToolTip from "../../component/rogSuccessTooltip"
import useAddImageToCollection from "../../hooks/useAddImageToCollection"
import useCollectionNames from "../../hooks/useCollectionNames"
import Image from "./Image"
import Collection from "../V2Leaderboard/Collection"
import AllImagesSaveButtonToCollection from "../../component/AddAllImagesToCollection"
import useMixpanelTrack from "../../hooks/useMixpanelTracking"

const TrendImages = ({ searchParams, tag, value, isPage = false, isMainPage = false, level }) => {
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const [socialCount, setSocialCount] = useState(1)
  const [retailerCount, setRetailerCount] = useState(1)
  const [socialImages, setSocialImages] = useState([])
  const [retailerImages, setRetailerImages] = useState([])
  const [allCollectionNames, socialcollectionSets, retailercollectionSets, getCollectionNames] = useCollectionNames()
  const addImageToCollection = useAddImageToCollection()
  const [loading, setLoading] = useState(false)
  const TrackEvent = useMixpanelTrack()
  const [resultsLoading, setResultsLoading] = useState(false)
  const [heading, setHeading] = useState('')
  const limit = 100



  useEffect(() => {
    setCount(1)
    setPage(1)
  }, [])


  const getImages = async (signal) => {
    //    setLoading(true)
    setResultsLoading(true)
    try {
      let tagsQuery = ''
      const breakdownLevel = level
      for (let i = 0; i < breakdownLevel; i++) {
        tagsQuery = tagsQuery + `&tag-${i}=${encodeURIComponent(searchParams.get(`tag-${i}`))}&value-${i}=${encodeURIComponent(searchParams.get(`value-${i}`))}`
      }

      const resp = await axios.get(`/api/build-designs/images?gender=${searchParams.get('gender') || 'Female'}&category=${searchParams.get('category').split('&').join('%26')}&breakdown-level=${level}&tag=${tag}&value=${value}${tagsQuery}&page=${page}`, { signal })
      setLoading(false)
      setResultsLoading(false)
      setSocialCount(resp.data.socialCount)
      setRetailerCount(resp.data.retailerCount)
      setSocialImages(prev => {
        const newRes = [...prev, ...resp.data.socialImages]
        return newRes
      })
      setRetailerImages(prev => {
        const newRes = [...prev, ...resp.data.retailerImages]
        return newRes;
      })
      setCount(Math.max(resp.data.socialCount, resp.data.retailerCount))
    } catch (e) {
      if (!isAxiosError(e)) {
        console.log(e)
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      getImages(signal)
    } catch (e) {
      console.error(e)
    }
    return () => {
      abortController.abort()
    }
  }, [searchParams, page])

  useEffect(() => {
    let newHeading = ''
    for (let i = 0; i < level; i++) {
      newHeading = `${newHeading} ${searchParams.get(`value-${i}`)} ${searchParams.get(`tag-${i}`)} > `
    }
    if (tag && !tag.includes(';'))
      newHeading = `${newHeading} ${value} ${tag} `
    setHeading(newHeading)
  }, [searchParams, tag, value, level])

  const socialAddImageToCollectionWrapper = (image, collectionId) => {
    addImageToCollection(image._id, collectionId, socialcollectionSets, 'social', getCollectionNames);
  }

  const retailerAddImageToCollectionWrapper = (image, collectionId) => {
    addImageToCollection(image._id, collectionId, retailercollectionSets, 'retailer', getCollectionNames);
  }

  const addAllImagesToCollection = (collectionId) => {
    addImageToCollection(null, collectionId, null, null, getCollectionNames, true, retailerImages.map(p => p._id), socialImages.map(p => p._id))
  }

  return <div className={`${isPage ? "" : "w-[80vw] h-[80vh]"} bg-white`}>
    {isPage || !isMainPage ? null : <div className="flex justify-end">
      <button onClick={() => {
        let tagsUrl = ''
        for (let i = 0; i < level; i++) {
          tagsUrl = tagsUrl + `&tag-${i}=${encodeURIComponent(searchParams.get(`tag-${i}`))}&value-${i}=${encodeURIComponent(searchParams.get(`value-${i}`))}`
        }
        window.navigator.clipboard.writeText(`${window.location.host}/build-design-images?gender=${searchParams.get('gender')}&category=${searchParams.get('category')}&breakdown-level=${level}&tag=${encodeURIComponent(tag)}&value=${encodeURIComponent(value)}${tagsUrl}`)
        Swal.fire({ title: "URL Copied", timer: 1000, customClass: "z-[20000]", })
      }}
        className="primary-btn text-sm py-1"
      >Copy URL</button>
    </div>}
    {!isMainPage ? <h3 className="text-center font-semibold text-[20px]">{heading} Trend Images</h3> : null}
    {!isMainPage ? <div className="flex justify-end">
      <div>
        <button className="primary-btn" onClick={() => { setPage(prev => prev - 1) }} disabled={page <= 1}>prev</button> {page}/{Math.ceil(count / limit)}
        <button className="primary-btn" onClick={() => { setPage(prev => prev + 1) }} disabled={page >= Math.ceil(count / limit)}>next</button>
      </div>
    </div>
      : null}
    <div className="absolute z-[10] bg-white w-[49%]">
      <div className="flex justify-center w-full">
        <AllImagesSaveButtonToCollection collections={allCollectionNames} addImageToCollection={addAllImagesToCollection} fetchCollections={getCollectionNames} />
      </div>
    </div>

    <Loadingwrapper loading={loading} textToShow="Loading Images…">
      {/*
      {socialImages.map(im => <RogSuccessToolTip image={im} trendType={'social'} category={searchParams.get('category')} />)}
      {retailerImages.map(im => <RogSuccessToolTip image={im} trendType={'retailer'} category={searchParams.get('category')} />)}
      */}

      <div className={`flex justify-between pt-10`} >
        <div className="w-[49%] shrink-0">
          {!isMainPage ? <h3 className="text-center font-medium text-lg mb-2">Social Images</h3> : null}
          <div className="flex flex-wrap">
            {socialImages.map(img => <Image image={img} trendType={'social'} addImageToCollection={socialAddImageToCollectionWrapper} allCollectionNames={allCollectionNames} collectionSets={socialcollectionSets} isMainPage={isMainPage} fetchCollections={getCollectionNames} />)}
          </div>
        </div>
        <div className="w-0.5 bg-black"></div>
        <div className="w-[49%] shrink-0">
          {!isMainPage ? <h3 className="text-center font-medium text-lg mb-2">Retailer Images</h3> : null}
          <div className="flex flex-wrap">

            {retailerImages.map(img => <Image image={img} trendType={'retailer'} addImageToCollection={retailerAddImageToCollectionWrapper} allCollectionNames={allCollectionNames} collectionSets={retailercollectionSets} isMainPage={isMainPage} fetchCollections={getCollectionNames} />)}
          </div>
        </div>
      </div>
      {isMainPage ? <div className="flex justify-center items-center py-4">
        <Loadingwrapper loading={resultsLoading} textToShow="Loading Images…">
          {
            page >= Math.ceil(count / limit)
              ? <p>End of results. </p>
              : <button className="primary-btn" onClick={() => {
                setResultsLoading(true)
                TrackEvent("Image Column Load More button Press", { category: searchParams.get('category'), page: page + 1 })
                setPage(prev => prev + 1)
              }}>Load More</button>
          }
        </Loadingwrapper>
      </div> : null}
    </Loadingwrapper>


  </div >
}

export default TrendImages
