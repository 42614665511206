import { Tooltip } from "react-tooltip";
function TrendTooltip({ image, trendType = 'social', category }) {
  return <Tooltip id={'trends-tooltip-' + (trendType == 'social' ? image.mediaId : image.pk)} style={{ zIndex: 1100 }}>
    <div className="text-[10px] font-light">
      <h3 className="text-center">Trends of image</h3>
      {image.trends.filter(catTrends => catTrends.category == category).map((catTrends, i) => <div className="my-2">
        <div className="flex justify-between py-1 text-xs">
          <h4>{catTrends.category}</h4>
          <div className="flex">
            {trendType == 'social' ? <div className="px-2 ">{catTrends.aggregateRateOfGrowth} Sum ROG(social)</div> : <>
              <div className="px-2 text-yellow-600 w-20 text-wrap shrink-0">{catTrends.socialAggregateRateOfGrowth} Sum ROG(social)</div>
              {false ? <div className="px-2 w-20 shrink-0 text-wrap">{catTrends.aggregateRateOfGrowth} Sum ROG(retailer)</div> : null}
            </>}
            <h3>{catTrends.success} Success points </h3>
          </div>
        </div>
        <div className="ml-4">

          {
            catTrends.trends.sort((a, b) => b.success - a.success).map(sil => <div key={sil.silhouette}>
              <div className="flex justify-between w-full pr-2 mb-1 mt-3 text-xs">
                <div>{sil.silhouette}</div>
                <div className="flex">
                  {trendType == 'social' ? <div className="px-2 ">{sil.aggregateRateOfGrowth} Sum ROG(social)</div> : <>
                    <div className="px-2 text-yellow-600 w-20 text-wrap shrink-0">{sil.socialAggregateRateOfGrowth} Sum ROG(social)</div>
                    {false ? <div className="px-2 w-20 shrink-0 text-wrap">{sil.aggregateRateOfGrowth} Sum ROG(retailer)</div> : null}
                  </>}
                  <div>{sil.success} Success points</div>
                </div>
              </div>

              {sil.trends.map(trend => <div className="flex justify-between px-2">
                <p className="text-wrap pr-2">{trend.primaryTagValue} <span className="font-semibold">x</span> {trend.secondaryValue}</p>
                <div className="flex">
                  {trendType == 'social' ? <p className="px-2">{Math.ceil(trend.rateOfGrowth * 1000) / 10}%</p> : <>
                    <p className="px-2 text-yellow-600">{Math.ceil(trend.socialrateOfGrowth * 1000) / 10}%</p>
                    {false ? <p className="px-2">{Math.ceil(trend.rateOfGrowth * 1000) / 10}%</p> : null}
                  </>
                  }
                  <p>{trend.lifecycle}</p>
                </div>
              </div>)}
            </div>)
          }
        </div>
      </div>)}

    </div>
  </Tooltip>

}

export default TrendTooltip;
