import { useEffect, useState } from "react"
import Loadingwrapper from "../../component/LoadingWrapper"
import TrendImagesType from "./TrendImagesType";

const TrendImages = ({ trendType, searchParams, setSearchParams, tag, value, isPage = false }) => {
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const [brandCounts, setBrandCounts] = useState([])
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [imagesSort, setImagesSort] = useState('Success points')
  const [socialLoading, setSocialLoading] = useState(true)
  const [retailerLoading, setRetailerLoading] = useState(true)
  const limit = 100


  useEffect(() => {
    if (searchParams.get('brand')) {
      setSelectedBrand(searchParams.get('brand'))
    }
  }, [])

  useEffect(() => {
    setCount(1)
    setPage(1)
  }, [selectedBrand])



  return <div className={`${isPage ? "" : "w-[80vw] h-[80vh]"} bg-white`}>
    {isPage ? <div className="w-full my-8"></div> : <div className="flex justify-end">
      <button onClick={() => {
        window.navigator.clipboard.writeText(`${window.location.host}/v2-trend?trends=${trendType}&selectedtag=${encodeURIComponent(tag)}&selectedvalue=${encodeURIComponent(value)}&${searchParams.toString()}`)
      }}
        className="primary-btn text-sm py-1"
      >Copy URL</button>
    </div>}
    <h3 className="text-center font-semibold text-[20px]">{value != '#N/A' ? value : 'No Specific'} {tag} Trend Images</h3>
    <div className="flex justify-end">

      <div className="">
        <button className="primary-btn" onClick={() => { setPage(prev => prev - 1) }} disabled={page <= 1}>prev</button> {page}/{Math.ceil(count / limit)}
        <button className="primary-btn" onClick={() => { setPage(prev => prev + 1) }} disabled={page >= Math.ceil(count / limit)}>next</button>
      </div>
    </div>
    <div className="flex justify-start">
      {
        false ? <select value={imagesSort} onChange={e => setImagesSort(e.target.value)} className='bg-slate-100 rounded px-2 py-1 font-semibold text-xs border-slate-300 border '>
          <option value={''} disabled>--Sort Images--</option>
          <option value={'Date'}>Date</option>
          <option value={'Success points'}>Success Points</option>
        </select>
          : null
      }
      {isPage ? <select className='px-2 rounded  border-slate-300 border mx-1' value={searchParams.get('time')} onChange={(e) => {
        setSearchParams(prev => {
          prev.set('time', e.target.value);
          return prev;
        })
      }}>
        <option value={""}>All Time</option>
        <option value={"7 days"}>7 days</option>
        <option value={"15 days"}>15 days</option>
        <option value={"30 days"}>30 days</option>
        <option value={"45 days"}>45 days</option>
        <option value={"60 days"}>60 days</option>
        <option value={"90 days"}>90 days</option>
        <option value={"180 days"}>180 days</option>
      </select> : null}
    </div>
    <div className="flex justify-between">
      <div>
        <div className="flex  border-slate-200 overflow-x-auto h-13 py-2 hover:h-auto w-[60vw] hover:flex-wrap transition-[height] transition-all duration-1000">
          {brandCounts.map(brand => <button
            key={brand._id}
            onClick={() => {
              setSelectedBrand(prev => {
                return brand._id != prev ? brand._id : null
              })
            }}
            className={` text-nowrap border rounded-2xl px-2 text-xs font-medium hover:bg-slate-100 ${selectedBrand == brand._id ? "bg-slate-300" : "bg-white"} py-1 ml-1`}
          >{brand._id}({brand.count})</button>)}
        </div>
      </div>
    </div>

    <div className={`flex justify-between ${socialLoading || retailerLoading ? 'hidden' : ''}`} >
      <TrendImagesType trendType={'social'} searchParams={searchParams} tag={tag} value={value} imagesSort={imagesSort} setCount={setCount} setBrandCounts={setBrandCounts} setLoading={setSocialLoading} page={page} />
      <div className="w-0.5 bg-black"></div>
      <TrendImagesType trendType={'retailer'} searchParams={searchParams} tag={tag} value={value} imagesSort={imagesSort} setCount={setCount} setBrandCounts={setBrandCounts} setLoading={setRetailerLoading} selectedBrand={selectedBrand} page={page} />
    </div>
    <Loadingwrapper loading={socialLoading || retailerLoading}>
    </Loadingwrapper>

    <div className="flex justify-end">

      <div className="">
        <button className="primary-btn" onClick={() => { setPage(prev => prev - 1) }} disabled={page <= 1}>prev</button> {page}/{Math.ceil(count / limit)}
        <button className="primary-btn" onClick={() => { setPage(prev => prev + 1) }} disabled={page >= Math.ceil(count / limit)}>next</button>
      </div>
    </div>

  </div >
}

export default TrendImages
