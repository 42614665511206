import ModalImage from "react-modal-image"
import RogSuccessToolTip from "../../component/rogSuccessTooltip"
import ImageTagsDetails from "../SocialTrendsTags/ImageTagsDetails"
import Collection from "../V2Leaderboard/Collection"

const Image = ({ image, trendType, allCollectionNames, addImageToCollection, collectionSets, isMainPage = false, fetchCollections }) => {
  return <div className={`w-1/2 p-2 border border-slate-300 h-[${isMainPage ? '19' : '21'}rem] overflow-visible`}>
    <div className="flex justify-center items-center relative group">
      <div className="relative group " >
        <Collection image={image} collections={allCollectionNames} addImageToCollection={addImageToCollection} collectionSets={collectionSets} fetchCollections={fetchCollections} />
        <div data-tooltip-id={'trends-tooltip-' + (trendType == 'social' ? image.mediaId : image.pk)}>
          <ModalImage
            small={image[trendType == 'social' ? 'imageUrl' : 'image_url']}
            large={image[trendType == 'social' ? 'imageUrl' : 'image_url']}
            className='h-[16rem]'
          />
        </div>
      </div>
    </div>
    {trendType == 'social' ? <div className="px-3 text-xs">
      <a href={`${image.postUrl}`} className='text-blue-600 font-medium' target='_blank'>{image.username}</a>
      <p>{new Date(image.timeStamp).toDateString()}</p>
      <ImageTagsDetails mediaId={image.mediaId} />
    </div> : <div className="px-3 text-xs">
      <a href={`${image.product_url}`} className='text-blue-600 font-medium' target='_blank'>{image.retailer}</a>
      <p>Brand : {image.brand}</p>
      <p className="w-full text-nowrap overflow-hidden text-[11px]">{image.title}</p>
      <ImageTagsDetails mediaId={image.pk} isretailer={true} />
    </div>}

  </div>


}


export default Image
