import axios from "axios";
import { useEffect, useState } from "react";

function useCollectionNames() {

  const [allCollectionNames, setAllCollectionNames] = useState([])
  const [socialcollectionSets, setSocialCollectionSets] = useState({})
  const [retailercollectionSets, setRetailerCollectionSets] = useState({})

  const getCollectionNames = async () => {
    const resp = await axios.get(`/api/saved-images/collections`)
    setAllCollectionNames(resp.data.collections)
    let socialcollectionSetsTemp = {}
    let retailercollectionSetsTemp = {}
    resp.data.collections.forEach(coll => {
      socialcollectionSetsTemp[coll._id] = new Set(coll['savedImages']);
      retailercollectionSetsTemp[coll._id] = new Set(coll['savedProducts']);
    })
    setSocialCollectionSets(socialcollectionSetsTemp)
    setRetailerCollectionSets(retailercollectionSetsTemp)
  }
  useEffect(() => {
    getCollectionNames()
  }, [])
  return [allCollectionNames, socialcollectionSets, retailercollectionSets, getCollectionNames]
}

export default useCollectionNames;
