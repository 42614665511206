import axios from "axios"

function useMixpanelTrack() {

  const trackMixpanelEvent = async (eventName, eventbody) => {
    const body = {
      eventName: eventName,
      eventBody: eventbody
    }
    await axios.post(`/api/mixpanel-track/event`, body)

  }
  return trackMixpanelEvent
}

export default useMixpanelTrack;
