import axios, { isAxiosError } from "axios"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import Loadingwrapper from "../../component/LoadingWrapper"
import RogSuccessToolTip from "../../component/rogSuccessTooltip"
import useAddImageToCollection from "../../hooks/useAddImageToCollection"
import useCollectionNames from "../../hooks/useCollectionNames"
import Image from "../BuildDesigns/Image";
import Collection from "../V2Leaderboard/Collection"
import AllImagesSaveButtonToCollection from "../../component/AddAllImagesToCollection"
import useMixpanelTrack from "../../hooks/useMixpanelTracking"

const TrendImages = ({ tag, value, category, gender }) => {
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const [socialCount, setSocialCount] = useState(1)
  const [retailerCount, setRetailerCount] = useState(1)
  const [socialImages, setSocialImages] = useState([])
  const [retailerImages, setRetailerImages] = useState([])
  const [allCollectionNames, socialcollectionSets, retailercollectionSets, getCollectionNames] = useCollectionNames()
  const addImageToCollection = useAddImageToCollection()
  const [loading, setLoading] = useState(false)
  const TrackEvent = useMixpanelTrack()
  const [resultsLoading, setResultsLoading] = useState(false)
  const [heading, setHeading] = useState('')
  const [selectedSilhouette, setSelectedSilhouette] = useState(null)
  const [silhouetteFilters, setSilhouetteFilters] = useState([])
  const limit = 100



  useEffect(() => {
    setCount(1)
    setPage(1)
  }, [tag, value, selectedSilhouette])

  const getFilters = async () => {
    const response = await axios.get(`/api/build-designs/trends?gender=${gender == 'F' ? 'Female' : 'Male'}&category=${encodeURIComponent(category)}&breakdown-level=1&tag-0=${tag}&value-0=${encodeURIComponent(value)}`)
    setSilhouetteFilters(response.data.trends)
  }

  useEffect(() => {
    getFilters()
  }, [tag, value, category, gender])


  const getImages = async (signal) => {
    //    setLoading(true)
    setLoading(true)
    try {
      let tagsQuery = ''
      if (selectedSilhouette) {
        tagsQuery = tagsQuery + `&tag-${0}=${encodeURIComponent(selectedSilhouette.tag)}&value-${0}=${encodeURIComponent(selectedSilhouette.value)}`
      }

      const resp = await axios.get(`/api/build-designs/images?gender=${gender == 'F' ? 'Female' : 'Male'}&category=${encodeURIComponent(category)}&breakdown-level=${selectedSilhouette ? 1 : 0}&tag=${tag}&value=${value}${tagsQuery}&page=${page}`, { signal })
      setLoading(false)
      setSocialCount(resp.data.socialCount)
      setRetailerCount(resp.data.retailerCount)
      setSocialImages(prev => {
        const newRes = resp.data.socialImages
        return newRes
      })
      setRetailerImages(resp.data.retailerImages)
      setCount(Math.max(resp.data.socialCount, resp.data.retailerCount))
    } catch (e) {
      if (!isAxiosError(e)) {
        console.log(e)
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      getImages(signal)
    } catch (e) {
      console.error(e)
    }
    return () => {
      abortController.abort()
    }
  }, [tag, value, selectedSilhouette, page])


  const socialAddImageToCollectionWrapper = (image, collectionId) => {
    addImageToCollection(image._id, collectionId, socialcollectionSets, 'social', getCollectionNames);
  }

  const retailerAddImageToCollectionWrapper = (image, collectionId) => {
    addImageToCollection(image._id, collectionId, retailercollectionSets, 'retailer', getCollectionNames);
  }

  const addAllImagesToCollection = (collectionId) => {
    addImageToCollection(null, collectionId, null, null, getCollectionNames, true, retailerImages.map(p => p._id), socialImages.map(p => p._id))
  }

  return <div className={`${false ? "" : "w-[80vw] h-[80vh]"} bg-white`}>
    {false && <div className="flex justify-end">
      <button onClick={() => {
        let tagsUrl = ''
        Swal.fire({ title: "URL Copied", timer: 1000, customClass: "z-[20000]", })
      }}
        className="primary-btn text-sm py-1"
      >Copy URL</button>
    </div>}
    <h3 className="text-center font-semibold text-[20px]">{heading} Trend Images</h3>
    <div className="flex w-full px-4 overflow-x-scroll text-[14px] my-3">
      <button className="border-slate-600 border px-2 py-1 min-h-max disabled:cursor-not-allowed rounded disabled:bg-slate-200 text-black font-semibold " disabled={!selectedSilhouette} onClick={() => { setSelectedSilhouette(null) }}>All Images</button>
      <div className="w-60 mx-4 flex flex-col justify-center">
        <p className="">Recommended Silhouettes >></p>
      </div>
      {
        silhouetteFilters
          .sort((a, b) => (b.tdcount7days - a.tdcount7days))
          .filter(sil => (sil.tdcount7days > 0))
          .map(sil => <button className=" min-w-48 border-slate-600 border px-2 py-1 h-full disabled:cursor-not-allowed rounded disabled:bg-slate-200 text-black font-semibold ml-2" disabled={selectedSilhouette && selectedSilhouette.tag == sil.tag && selectedSilhouette.value == sil.value}
            onClick={() => { setSelectedSilhouette({ tag: sil.tag, value: sil.value }) }}>
            <div>{sil.value}</div>
            <div>Social Rog :{parseInt(sil.rateOfGrowth * 1000) / 10}%</div>
            <div>7 day depth:{sil.tdcount7days}%</div>
          </button>)
      }
    </div>
    <div className="flex justify-end">
      <div>
        <button className="primary-btn" onClick={() => { setPage(prev => prev - 1) }} disabled={page <= 1}>prev</button> {page}/{Math.ceil(count / limit)}
        <button className="primary-btn" onClick={() => { setPage(prev => prev + 1) }} disabled={page >= Math.ceil(count / limit)}>next</button>
      </div>
    </div>
    <div className="absolute z-[10] bg-white w-[49%]" hidden={true}>
      <div className="flex justify-center w-full">
        <AllImagesSaveButtonToCollection collections={allCollectionNames} addImageToCollection={addAllImagesToCollection} fetchCollections={getCollectionNames} />
      </div>
    </div>

    <Loadingwrapper loading={loading} textToShow="Loading Images…">
      {/*
      {socialImages.map(im => <RogSuccessToolTip image={im} trendType={'social'} category={searchParams.get('category')} />)}
      {retailerImages.map(im => <RogSuccessToolTip image={im} trendType={'retailer'} category={searchParams.get('category')} />)}
      */}

      <div className={`flex justify-between pt-10`} >
        <div className="w-[49%] shrink-0">
          <h3 className="text-center font-medium text-lg mb-2">Social Images</h3>
          <div className="flex flex-wrap">
            {socialImages.map(img => <Image image={img} trendType={'social'} addImageToCollection={socialAddImageToCollectionWrapper} allCollectionNames={allCollectionNames} collectionSets={socialcollectionSets} fetchCollections={getCollectionNames} />)}
          </div>
        </div>
        <div className="w-0.5 bg-black"></div>
        <div className="w-[49%] shrink-0">
          <h3 className="text-center font-medium text-lg mb-2">Retailer Images</h3>
          <div className="flex flex-wrap">

            {retailerImages.map(img => <Image image={img} trendType={'retailer'} addImageToCollection={retailerAddImageToCollectionWrapper} allCollectionNames={allCollectionNames} collectionSets={retailercollectionSets} fetchCollections={getCollectionNames} />)}
          </div>
        </div>
      </div>
      {false ? <div className="flex justify-center items-center py-4">
        <Loadingwrapper loading={resultsLoading} textToShow="Loading Images…">
          {
            page >= Math.ceil(count / limit)
              ? <p>End of results. </p>
              : <button className="primary-btn" onClick={() => {
                setResultsLoading(true)
                TrackEvent("Image Column Load More button Press", { category: category, page: page + 1 })
                setPage(prev => prev + 1)
              }}>Load More</button>
          }
        </Loadingwrapper>
      </div> : null}
    </Loadingwrapper>


  </div >
}

export default TrendImages
