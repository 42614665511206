import ModalImage from "react-modal-image"
import { Tooltip } from "react-tooltip"
import ImageTagsDetails from "../SocialTrendsTags/ImageTagsDetails"
import Collection from "../V2Leaderboard/Collection"

const DesignRecipeImage = ({ image, gender, category, type, isBoth, allCollectionNames, addImageToCollection, collectionSets, fetchCollections }) => {

  return <div className={isBoth ? 'w-[24%]' : 'w-[12%]'}>

    <div className=" p-2 border border-slate-300 h-[23rem]">
      <div className="flex justify-center items-center relative group">
        <div className="relative group " >
          <Collection image={image} collections={allCollectionNames} addImageToCollection={addImageToCollection} collectionSets={collectionSets} fetchCollections={fetchCollections} />
          <div data-tooltip-id={'trends-tooltip-' + (type == 'social' ? image.mediaId : image.pk)}>
            <ModalImage
              small={image[type == 'social' ? 'imageUrl' : 'image_url']}
              large={image[type == 'social' ? 'imageUrl' : 'image_url']}
              className='h-60'
            />
          </div>
          {gender == 'F' && category != 'Ethinic Wear' && image.silhouetteTrends && image.silhouetteTrends.length > 0 ? <Tooltip id={'trends-tooltip-' + (type == 'social' ? image.mediaId : image.pk)} style={{ zIndex: 1100 }}>
            <div className="text-[10px] font-light w-[25rem]">
              <h3 className="text-center">Trends of image</h3>
              <div className="flex justify-between py-1 text-xs">
                <div className={`px-2 text-yellow-500 font-semibold text-wrap shrink-0`}>{Math.ceil(1000 * (image.socialAggregateRateOfGrowth || image.aggregateRateOfGrowth || 0)) / 10} Sum ROG(social)</div>
              </div>
              <div className="ml-2">{
                image.silhouetteTrends//.sort((a, b) => b.success - a.success)
                  .map(sil => <div key={sil.silhouette}>
                    <div className="flex justify-between w-full pr-2 mb-1 mt-3 text-xs">
                      <div>{sil.silhouette}</div>
                      <div className="flex">
                        {type == 'social' ? <div className="px-2 text-yellow-500  font-semibold">{Math.ceil(1000 * (sil.aggregateRateOfGrowth || 0)) / 10} Sum ROG(social)</div> : <>
                          <div className="px-2 text-yellow-500 font-semibold w-20 text-wrap shrink-0">{Math.ceil(1000 * (sil.socialAggregateRateOfGrowth || 0)) / 10} Sum ROG(social)</div>
                          {false ? <div className="px-2 w-20 shrink-0 text-wrap">{Math.ceil(1000 * (sil.aggregateRateOfGrowth || 0)) / 10} Sum ROG(retailer)</div> : null}
                        </>}
                        <div>{sil.success} Success points</div>
                      </div>
                    </div>

                    {sil.trends.map(trend => <div className="flex justify-between px-2">
                      <p className="text-wrap pr-2">{trend.primaryTagValue} <span className="font-semibold">x</span> {trend.secondaryValue}</p>

                      <div className="flex">
                        {
                          type == 'social' ? <p className="px-2  text-yellow-500 font-semibold">{Math.ceil((trend.rateOfGrowth || 0) * 1000) / 10}%</p> : <>
                            <p className="px-2 text-yellow-500 font-semibold">{Math.ceil((trend.socialRateOfGrowth || 0) * 1000) / 10}%</p>
                            {false ? <p className="px-2">{Math.ceil((trend.rateOfGrowth || 0) * 1000) / 10}%</p> : null}
                          </>
                        }
                        <p>{trend.lifecycle}</p>
                      </div>
                    </div>)}
                  </div>)


              }
              </div>

            </div>
          </Tooltip> : null}
        </div>
      </div>
      {
        image.trends && image.trends[0] && image.trends ? <div>
          {[...new Set(image.trends.map(t => t.primaryTagValue)).values()].map(val => <p className="text-xs px-3 font-bold" key={val}>{val}</p>)}
        </div> : null
      }
      {
        type == 'social' ? <div className="px-3 text-xs">
          <a href={`${image.postUrl}`} className='text-blue-600 font-medium' target='_blank'>{image.username}</a>
          <p>{new Date(image.timeStamp).toDateString()}</p>
          <ImageTagsDetails mediaId={image.mediaId} />
        </div> : <div className="px-3 text-xs">
          <a href={`${image.product_url}`} className='text-blue-600 font-medium' target='_blank'>{image.retailer || 'Product '}</a>
          {image.brand ? <p>Brand : {image.brand}</p> : null}
          <p className="w-full text-nowrap overflow-hidden text-[11px]">{image.title}</p>
          <p className="w-full text-nowrap overflow-hidden text-[11px]">{image.price}</p>
          <ImageTagsDetails mediaId={image.pk} isretailer={true} />
        </div>
      }

    </div >
  </div >
}

export default DesignRecipeImage
