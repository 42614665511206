import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaBookmark, FaPlus, FaRegBookmark } from 'react-icons/fa';
import { IoIosCheckmarkCircle } from 'react-icons/io'
import Popup from '../../component/Popup';
import NewCollection from './NewCollection';
const Collection = ({ collections, fetchCollections, image, addImageToCollection, collectionSets }) => {

  const [showCollections, setShowCollections] = useState(false)
  const [showNewCollection, setShowNewCollection] = useState(false)
  const addImageToCollectionById = async (e) => {
    addImageToCollection(image, e.target.id)
  }
  //invisible group-hover:visible
  return <><div className="absolute bottom-4 right-4  ">
    <div className={`font-semibold cursor-pointer text-[14px] border rounded-xl px-2 border-black ${Object.keys(collectionSets).map(id => collectionSets[id].has(image._id)).filter(t => t).length > 0 ? 'bg-red-600 hover:bg-red-700 text-white ' : ''}`}
      onMouseEnter={() => { setShowCollections(true); }}
      onMouseLeave={() => { setShowCollections(false); }}
    >{Object.keys(collectionSets).map(id => collectionSets[id].has(image._id)).filter(t => t).length > 0 ? 'Unsave' : 'Save'}
      {
        showCollections
          ? <div className='absolute  bg-slate-700/90  py-3 left-1/2 -translate-x-1/2 z-30 rounded min-w-80 cursor-auto max-h-[600px] overflow-y-auto text-white'>

            <div className='flex justify-between px-2 mb-2 py-1 '>
              <button className='ml-4 flex'
                onClick={() => setShowNewCollection(true)}
              >
                <span className='mr-4'>Add New Collection</span>
                <FaPlus color='white' size={14} />
              </button>
            </div>
            {
              collections.map(c => <button id={c._id} onClick={addImageToCollectionById} className={`w-full py-2 text-left pl-2 ${collectionSets[c._id].has(image._id) ? 'bg-slate-600 hover:bg-slate-500 font-semibold' : 'bg-slate-700 hover:bg-slate-600'} flex justify-between pr-4`} >{c.collectionName} {collectionSets[c._id].has(image._id) ? <IoIosCheckmarkCircle size={20} /> : <div></div>}</button>)
            }
          </div>

          : null}
    </div>
  </div>
    <NewCollection show={showNewCollection} setIsPopUp={setShowNewCollection} image={image} addImageToCollection={addImageToCollection} fetchCollections={fetchCollections} collections={collections} />
  </>

}

export default Collection
