import { FaImages } from "react-icons/fa"
import NewsFeedImage from "./image"
import { AiTwotoneHeart } from "react-icons/ai"
import speedometer from "./speedometer.png";
import measure from "./measure.png";
import { useEffect, useState } from "react";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import Popup from "../../component/Popup";
import TrendImages from "./ImagesPopup";
import HistoryGraph from "./HistoryGraph";
import { useSelector } from "react-redux";

const NewsFeedTrend = ({ trend, rank, allCollectionNames, addImageToCollection, collectionSets, fetchCollections, retailerAddImageToCollection, retailercollectionSets, trendType, gender, category }) => {
  const [showRetailer, setShowRetailer] = useState(false)
  const [showProductsPopup, setShowProductsPopup] = useState(false)
  const [showHistoryGraph, setShowHistoryGraph] = useState(false)
  const userLoggedIn = useSelector(state => state.userLoggedIn)
  return <div className=' border-b-2 border-b-slate-500 even:bg-slate-100'>
    <div className='flex justify-between items-center  pt-2'>
      <div className='flex items-center flex-wrap'>
        <p className="text-[32px]">{rank}.<span className="font-semibold">{trend.value}</span> {trend.tag}</p>
        <p className='mx-2' hidden>Category: <span className='font-bold'>{trend.category}</span></p>
        {false ? <p className='mx-2 flex items-center'>{trend.allCount} <span className='text-xl'> <FaImages /></span></p> : null}
        <p className='mx-2 flex items-center'><img src={speedometer} alt="" className="h-4 mr-1" /> Weekly-Rate-of-Growth:<span className="font-semibold pl-1">{parseInt(trend.rateOfGrowth * 1000) / 10}%{trend.rogState ? <span className="pl-2">(Is {trend.rogState} Rate of Growth)</span> : null}</span></p>
        {false && <p className='mx-2 flex items-center' hidden>Avg-Rank: {trend.avgRank}</p>}
        {trend.totalLikes && trend.totalLikes > 1 && <p className='mx-2 flex items-center'>{trend.totalLikes} <span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></p>}


      </div>
    </div>
    <div>

      <p className='mx-2 flex items-center' data-tooltip-id={'depth-tooltip-' + '0' + trend.tag + '-' + trend.value}><img src={measure} alt="" className="h-4 mr-1" />Weekly-Depth:<span className="font-semibold"> {parseInt(trend.depth * 1000) / 10}%</span><span className="italic"> ({trend.DepthState ? <span className="font-semibold"> is {trend.DepthState} depth, </span> : null} In {gender == 'M' ? 'Men' : 'Women'}'s {category}, out of 100% {trend.tag}, {parseInt(trend.depth * 1000) / 10}% were {trend.value}, in the past 7 days. And growing week on week.) <span className="underline" hidden>(See Others)</span></span></p>

    </div>
    <div className='flex overflow-auto w-[98vw]'>
      {
        trend.images.map((image, index) => <NewsFeedImage image={image} key={index} allCollectionNames={allCollectionNames} addImageToCollection={addImageToCollection} collectionSets={collectionSets} fetchCollections={fetchCollections} trendType={trendType} />)
      }
    </div>
    <div>
      <div className="flex justify-center">
        <button className="flex font-semibold primary-btn my-1 text-[16px]" onClick={() => { setShowRetailer(prev => !prev) }}>See Retailer Images<span className="mt-1 ml-1">{showRetailer ? <IoIosArrowDropup strokeWidth={10} /> : <IoIosArrowDropdown strokeWidth={10} />}</span></button>
        <a href={`/build-designs?gender=${gender == 'M' ? 'Male' : 'Female'}&category=${encodeURIComponent(category)}&source=&sort=rog&breakdown-level=1&tag=&value=&tag-0=${encodeURIComponent(trend.tag)}&value-0=${encodeURIComponent(trend.value)}`} className="flex font-semibold primary-btn my-1 text-[16px] ml-2 bg-yellow-600 hover:bg-yellow-700 active:bg-yellow-800" target={'_blank'} >Design {trend.value} {trend.category}</a>
        {userLoggedIn && userLoggedIn.curator ? <button className="flex font-semibold primary-btn my-1 text-[16px] ml-2 bg-gray-600 hover:bg-gray-700 active:bg-gray-800" onClick={() => { setShowHistoryGraph(true) }}>See Graph</button> : null}
      </div>
      <div className={` grid grid-rows-1 ${showRetailer ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'} transition-all duration-1000`}>
        {showRetailer ? <div className={`w-full flex overflow-x-auto  border-t-slate-500`} >
          {
            trend.retailerImages.map((image, index) => <NewsFeedImage image={image} key={index} allCollectionNames={allCollectionNames} addImageToCollection={retailerAddImageToCollection} collectionSets={retailercollectionSets} fetchCollections={fetchCollections} trendType={'retailer'} />)
          }
        </div> : null}
      </div>

    </div>
    {showProductsPopup ? <Popup isPopUp={showProductsPopup} setIsPopUp={setShowProductsPopup}>
      <TrendImages tag={trend.tag} value={trend.value} category={trend.category} gender={gender} />
    </Popup> : null}
    {showHistoryGraph ? <Popup isPopUp={showHistoryGraph} setIsPopUp={setShowHistoryGraph}>
      <HistoryGraph tag={trend.tag} value={trend.value} category={trend.category} gender={gender} />
    </Popup> : null}
  </div >

}

export default NewsFeedTrend
