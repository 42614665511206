import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CategoriesSortHomePage } from "../../constants"
import useMixpanelTrack from "../../hooks/useMixpanelTracking"
import { MenCategoryThumbnails, WomenCategoryThumbnails } from "../../utility/CategoryThumbnails"


const V2CategoriesHomePage = () => {

  const userLoggedIn = useSelector(state => state.userLoggedIn)
  const navidate = useNavigate()
  const trackEvent = useMixpanelTrack()
  const setCategory = (cat, gender, type) => {
    //   if (location.pathname == '/women-social-trends' || location.pathname == '/men-social-trends') {
    //      setSearchParams(prev => {
    //        prev.set('category', cat);
    //        return prev;
    //      })
    trackEvent(`Homepage ${type.split('-').join(' ')} button click`, { category: cat, gender })
    const url = type == 'trends' ? `${gender == 'Female' ? '/women-social-trends' : 'men-social-trends'}?category=${cat.split('&').join('%26')}` :
      type == 'leaderboard' ? `v2-leaderboard?gender=${gender}&category=${encodeURIComponent(cat)}&time=${cat == 'Ethinic Wear' ? '60' : gender == 'Male' ? '30' : '15'}+days&geography=All&source=` :
        type == 'design-images' ? `design-recipe-images?gender=${gender == 'Female' ? 'F' : 'M'}&category=${encodeURIComponent(cat)}&time=${cat == 'Ethinic Wear' ? '180' : '15'}+days&geography=${cat == 'Ethinic Wear' ? 'All' : 'All'}${cat == 'Ethinic Wear' ? '&tab=social&sort=recency' : ''}` :
          type == 'design-builder' ? `build-designs?gender=${gender}&category=${encodeURIComponent(cat)}` :
            type == 'news-feed' ? `/news-feed?gender=${gender == 'Female' ? 'F' : 'M'}&category=${encodeURIComponent(cat)}` :
              `design-trends-lifecycle?gender=${gender}&category=${encodeURIComponent(cat)}&time=7+days&geography=All&source=&trends=social`
    navidate(url)
  }
  return <div className="flex w-screen justify-between">
    <div className="w-1/2 border-r-4 border-r-slate-400">
      <h2 className="text-center my-4 text-lg font-semibold">Women's Categories</h2>
      <div className=" flex shrink-0 flex-wrap">
        {Object.keys(WomenCategoryThumbnails).sort((a, b) => (CategoriesSortHomePage.indexOf(a) - CategoriesSortHomePage.indexOf(b))).map(cat => <div key={cat} className=' p-2 w-1/4 shrink-0 flex justify-center'>
          <div className='w-4/5 rounded-lg border hover:shadow-lg relative group'>
            <div className="flex justify-center w-full p-2">
              <img src={WomenCategoryThumbnails[cat]} alt={cat} className='w-4/5' />
            </div>
            <p className="text-lg font-medium group-hover:font-semibold">{cat}</p>
            <div className="absolute group-hover:visible invisible bottom-0 w-full   text-[12px] bg-slate-100/90 rounded-t-xl">
              <button onClick={() => { setCategory(cat, 'Female', 'leaderboard') }} className="w-full py-1 hover:shadow font-semibold">Trend Leaderboard</button>
              <button onClick={() => { setCategory(cat, 'Female', 'news-feed') }} className="w-full py-1 hover:shadow font-semibold" hidden={["Ethinic Wear", "Other Outerwear", "Jumpsuits"].includes(cat)}>News Feed</button>
              <button onClick={() => { setCategory(cat, 'Female', 'design-images') }} className="w-full py-1 hover:shadow font-semibold">Product Leaderboard</button>
              {cat != 'Ethinic Wear' ? <button onClick={() => { setCategory(cat, 'Female', 'design-builder') }} className="w-full py-1 hover:shadow font-semibold">Design Builder</button> : null}
              <button onClick={() => { setCategory(cat, 'Female', 'lifecycles') }} className="w-full py-1 hover:shadow font-semibold" hidden>Lifecycle</button>
              {
                userLoggedIn && userLoggedIn.curator && <button onClick={() => { setCategory(cat, 'Female', 'trends') }} className="w-full py-1 hover:shadow font-semibold">Deep Dive</button>
              }
            </div>
          </div>
        </div>)}
      </div>
    </div>

    <div className="w-1/2 ">
      <h2 className="text-center my-4 text-lg font-semibold">Men's Categories</h2>
      <div className=" flex shrink-0 flex-wrap">
        {Object.keys(MenCategoryThumbnails).sort((a, b) => (CategoriesSortHomePage.indexOf(a) - CategoriesSortHomePage.indexOf(b))).map(cat => <div key={cat} className=' p-2 w-1/4 shrink-0 flex justify-center'>
          <div className='w-4/5 rounded-lg border hover:shadow-lg relative group'>
            <div className="flex justify-center w-full p-2 ">
              <img src={MenCategoryThumbnails[cat]} alt={cat} className='w-4/5' />
            </div>
            <p className="text-lg font-medium group-hover:font-semibold">{cat}</p>
            <div className="absolute group-hover:visible invisible bottom-0 w-full  text-[12px] bg-slate-100/90 rounded-t-xl">
              <button onClick={() => { setCategory(cat, 'Male', 'leaderboard') }} className="w-full py-1 hover:shadow font-semibold">Trend Leaderboard</button>
              <button onClick={() => { setCategory(cat, 'Male', 'news-feed') }} className="w-full py-1 hover:shadow font-semibold" hidden={["Ethinic Wear", "Other Outerwear", "Jumpsuits"].includes(cat)}>News Feed</button>
              <button onClick={() => { setCategory(cat, 'Male', 'design-images') }} className="w-full py-1 hover:shadow font-semibold">Product Leaderboard</button>
              {cat != 'Ethinic Wear' ? <button onClick={() => { setCategory(cat, 'Male', 'design-builder') }} className="w-full py-1 hover:shadow font-semibold">Design Builder</button> : null}
              <button onClick={() => { setCategory(cat, 'Male', 'lifecycles') }} className="w-full py-1 hover:shadow font-semibold" hidden>Lifecycle</button>
              {
                userLoggedIn && userLoggedIn.curator && <button onClick={() => { setCategory(cat, 'Male', 'trends') }} className="w-full py-1 hover:shadow font-semibold">Deep Dive</button>
              }
            </div>
          </div>
        </div>)}
      </div>
    </div>
  </div>
}

export default V2CategoriesHomePage
