import axios from "axios"

function useAddImageToCollection() {

  const addImageToCollection = async (imageId, collectionId, collectionSets, trendType, getCollectionNames, isSetOfImages = false, retailerIds, socialIds) => {
    const body = {
      imageId: imageId,
      collectionId
    }
    if (!isSetOfImages && collectionSets[collectionId] && collectionSets[collectionId].has(imageId)) {
      body.delete = true
    }
    if (!isSetOfImages) {
      await axios.post(`/api/saved-images/image/${trendType}`, body)
    } else {
      body.imageId = {
        retailerIds, socialIds
      }
      await axios.post(`/api/saved-images/image-set`, body)
    }
    getCollectionNames()
  }
  return addImageToCollection
}

export default useAddImageToCollection;
