import axios, { isAxiosError } from "axios"
import { useEffect, useState } from "react"
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts"
import Loadingwrapper from "../../component/LoadingWrapper"
import useMixpanelTrack from "../../hooks/useMixpanelTracking"


const HistoryGraph = ({ tag, value, category, gender }) => {
  const [loading, setLoading] = useState(false)
  const TrackEvent = useMixpanelTrack()
  const limit = 100
  const [data, setData] = useState([])







  const getGraphData = async (signal) => {
    //    setLoading(true)
    setLoading(true)
    try {

      const response = await axios.get(`/api/news-feed/timeseries-data?gender=${gender}&category=${encodeURIComponent(category)}&tag=${encodeURIComponent(tag)}&value=${encodeURIComponent(value)}`, { signal })
      setData(response.data)
      setLoading(false)
    } catch (e) {
      if (!isAxiosError(e)) {
        console.log(e)
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      getGraphData(signal)
    } catch (e) {
      console.error(e)
    }
    return () => {
      abortController.abort()
    }
  }, [tag, value])



  return <div className={`${false ? "" : "w-[80vw] h-[80vh]"} bg-white`}>
    <h3 className="text-center font-semibold text-xl">History Graph</h3>
    <Loadingwrapper loading={loading} textToShow="Loading Images…">
      <div className="flex justify-center items-center py-4">
        <Loadingwrapper loading={loading} textToShow="Loading Images…">
          <ResponsiveContainer height={600} width="95%" >
            <LineChart padding={{ top: 5, bottom: 15, right: 10 }} margin={{ top: 5, bottom: 15, right: 10 }} data={data} >
              <Line type="monotone" dataKey="Depth" stroke="#8884d8" />
              <Line type="monotone" dataKey="Rate Of Growth" stroke="#82ca9d" />
              <Legend />
              <Tooltip />
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="date" angle={-45} fontSize={8} offset={400} startOffset={100} padding={{ left: 30, right: 30 }}>
              </XAxis>
              <YAxis allowDecimals={true} >
              </YAxis>
            </LineChart>
          </ResponsiveContainer>
        </Loadingwrapper>
      </div>
    </Loadingwrapper>


  </div >
}

export default HistoryGraph
