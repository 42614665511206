import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import axios from 'axios'
import Loadingwrapper from '../../component/LoadingWrapper';
import DesignRecipeImage from './DesignRecipeImage';
import Select from 'react-dropdown-select';
import useAddImageToCollection from '../../hooks/useAddImageToCollection';
import useCollectionNames from '../../hooks/useCollectionNames';
import { HeadersRanking, metaCategories, metaCategoriesMen } from '../../constants';

const DesignRecipeImages = ({ forAllImages = false }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const limit = 80
  const [socialImages, setSocialImages] = useState([])
  const [socialCount, setSocialCount] = useState(0)
  const [retailerImages, setRetailerImages] = useState([])
  const [retailerCount, setRetailerCount] = useState(0)
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(true)
  const [insertDatesOptions, setInsertDateOptions] = useState([])
  const [materialOptions, setMaterialOptions] = useState([])
  const [retailerOptions, setRetailerOptions] = useState([])
  const [SilhouetteOptions, setSilhouetteOptions] = useState([])
  const [socialSilhouetteCounts, setSocialSilhouetteCounts] = useState({})

  const [allCollectionNames, socialcollectionSets, retailercollectionSets, getCollectionNames] = useCollectionNames()
  const [page, setPage] = useState(1);
  const addImageToCollection = useAddImageToCollection()
  const [headers, setHeaders] = useState([])
  const socialAddImageToCollectionWrapper = (image, collectionId) => {
    addImageToCollection(image._id, collectionId, socialcollectionSets, 'social', getCollectionNames);
  }

  const retailerAddImageToCollectionWrapper = (image, collectionId) => {
    addImageToCollection(image._id, collectionId, retailercollectionSets, 'retailer', getCollectionNames);
  }
  useEffect(() => {
    setSearchParams(prev => {
      prev.set('gender', prev.get('gender') || forAllImages ? 'M' : 'F')
      prev.set('category', prev.get('category') || 'All')
      prev.set('tab', prev.get('tab') || 'both')
      prev.set('sort', prev.get('sort') || 'recency')
      prev.set('geography', prev.get('geography') || 'All')
      prev.set('time', prev.get('time') || '15 days')
      prev.set('page', prev.get('page') || '1')
      prev.set('headers', prev.get('headers') || '')
      return prev;
    })
  }, [])


  useEffect(() => {
    const tab = searchParams.get('tab')
    setMaxPage(tab == 'social' ? Math.ceil(socialCount / limit) : tab == 'retailer' ? Math.ceil(retailerCount / limit) : Math.ceil(Math.max(socialCount, retailerCount) / limit))
  }, [retailerCount, socialCount, searchParams.get('tab')])
  /*
    const getHeaders = (signal) => {
      axios.get(`/social-trends-v2-api/design-recipe-images-headerwise-counts/${searchParams.get('tab') || 'social'}?gender=${searchParams.get('gender') || 'Female'}&category=${encodeURIComponent(searchParams.get('category'))}&sort=${searchParams.get('sort')}&geography=${searchParams.get('geography') || ''}&time=${searchParams.get('time') || ''}&page=${searchParams.get('page') || '1'}&retailer=${searchParams.get('retailer') || ''}&insertDate=${searchParams.get('insertDate') || ''}&silhouette=${searchParams.get('silhouette') || ''}&limit=${limit}&headers=${searchParams.get('headers') || ''}`, { signal })
        .then(res => {
          setHeaders(res.data)
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
        })
    }
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getHeaders(signal)
    return () => {
      // Cancel the request when the component unmounts
      controller.abort();
    };
  }, [searchParams.get('tab'), searchParams.get('page'), searchParams.get('geography'), searchParams.get('time'), searchParams.get('gender'), searchParams.get('category'), searchParams.get('silhouette'), searchParams.get('insertDate'), searchParams.get('retailer')])
    */

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getImages(signal)
    return () => {
      // Cancel the request when the component unmounts
      controller.abort();
    };
  }, [searchParams.get('tab'), searchParams.get('page'), searchParams.get('geography'), searchParams.get('time'), searchParams.get('gender'), searchParams.get('category'), searchParams.get('silhouette'), searchParams.get('insertDate'), searchParams.get('retailer'), searchParams.get('sort'), searchParams.get('headers'), searchParams])
  const [isFirstRender, setIsFirstRender] = useState(true)
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
      return
    }
    setSearchParams(prev => {
      prev.set('page', '1')
      return prev;
    })

  }, [searchParams.get('tab'), searchParams.get('geography'), searchParams.get('time'), searchParams.get('gender'), searchParams.get('category'), searchParams.get('silhouette'), searchParams.get('insertDate'), searchParams.get('retailer'), searchParams.get('sort')])
  const getHeaderString = () => {
    let headerString = ''
    let headerList = []
    headers.forEach(header => {
      if (searchParams.get(header.tag)) {
        headerList.push(encodeURIComponent(header.tag))
        headerString = headerString + `&${encodeURIComponent(header.tag)}=${encodeURIComponent(searchParams.get(header.tag))}`
      }
    })
    return [headerList, headerString];
  }

  const getImages = (signal) => {
    setLoading(true)
    const [headerList, headerString] = getHeaderString()
    axios.get(`/social-trends-v2-api/design-recipe-images${searchParams.get('category') == 'All' ? '-all' : ''}/${searchParams.get('tab') || 'social'}?gender=${searchParams.get('gender') || 'Female'}&category=${encodeURIComponent(searchParams.get('category'))}&sort=${searchParams.get('sort')}&geography=${searchParams.get('geography') || ''}&time=${searchParams.get('time') || ''}&page=${searchParams.get('page') || '1'}&retailer=${searchParams.get('retailer') || ''}&insertDate=${searchParams.get('insertDate') || ''}&silhouette=${searchParams.get('silhouette') || ''}&limit=${limit}&headers=${headerList.join(',,')}${headerString}&material=${searchParams.get('material') || ''}`, { signal })
      .then(res => {
        setSocialImages(res.data.socialImages)
        setSocialCount(res.data.socialCount)
        setRetailerImages(res.data.retailerImages)
        setRetailerCount(res.data.retailerCount)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      })

  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getRetailerFilterCounts(signal)
    return () => {
      // Cancel the request when the component unmounts
      controller.abort();
    };
  }, [searchParams.get('gender'), searchParams.get('category'), searchParams.get('silhouette'), searchParams.get('insertDate'), searchParams.get('retailer'), searchParams.get('sort'), searchParams.get('time'), searchParams.get('headers'), searchParams])

  const getRetailerFilterCounts = (signal) => {
    const [headerList, headerString] = getHeaderString()
    axios.get(`/social-trends-v2-api/design-recipe-image-counts?gender=${searchParams.get('gender') || 'Female'}&category=${encodeURIComponent(searchParams.get('category'))}&geography=${searchParams.get('geography') || ''}&time=${searchParams.get('time') || ''}&insertDate=${searchParams.get('insertDate') || ''}&retailer=${searchParams.get('retailer') || ''}&silhouette=${searchParams.get('silhouette') || ''}&headers=${headerList.join(',,')}${headerString}`, { signal })
      .then(res => {
        setRetailerOptions(res.data.retailerCounts.map(t => ({ ...t, label: `${t.retailer} (${t.count})` })))
        setMaterialOptions(res.data.materialCounts.map(t => ({ ...t, label: `${t.material} (${t.count})` })))
        setInsertDateOptions(res.data.insertCounts.map(t => ({ ...t, label: `${t.insertDate} (${t.count})` })))
        const socialSilhouetteCounts = res.data.socialSilhouetteCounts
        const socialSilhouetteCountsObj = {}
        if (socialSilhouetteCounts) {
          socialSilhouetteCounts.forEach(c => {
            socialSilhouetteCountsObj[c.silhouette] = c.count
          })

        }
        setHeaders(res.data.headerCounts)
        setSocialSilhouetteCounts(socialSilhouetteCountsObj)
        setSilhouetteOptions(res.data.silhouetteCounts.map(t => ({ ...t, label: `${t.silhouette} (${socialSilhouetteCountsObj[t.silhouette] || 0} and ${t.count})` })))
      })
      .catch(err => {
        console.error(err)
      })
  }

  return <div>
    <h2 className='text-center m-2 font-semibold text-lg'>{searchParams.get('category')} Product Leaderboard</h2>
    <div className='flex mx-5'>
      {
        forAllImages ? <>
          <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('gender')} onChange={(e) => {
            setSearchParams(prev => {
              prev.set('gender', e.target.value);
              return prev;
            })
          }}>
            <option value={""} disabled>--Gender--</option>
            <option value={"F"}>Female</option>
            <option value={"M"}>Male</option>
          </select>
          <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('category')} onChange={(e) => {
            setSearchParams(prev => {
              prev.set('category', e.target.value);
              return prev;
            })
          }}>
            <option value={""} disabled>--Category--</option>
            <option value={"All"} >All Categories</option>
            {(searchParams.get('gender') == 'M' ? metaCategoriesMen : metaCategories).map(cat => <option key={cat} value={cat}>{cat}</option>)}
          </select>
        </>
          : null
      }
    </div>
    <div className='mx-5 max-[805px]:mx-0 mb-1 flex sticky top-12 mt-2 bg-white justify-between items-center z-10'>
      <div className='flex ' >


        <select className='px-2 rounded  border-slate-300 border m-1' value={searchParams.get('sort')} onChange={(e) => {
          setSearchParams(prev => {
            prev.set('sort', e.target.value)
            return prev
          })
        }}>
          <option value={''} disabled>--Sort--</option>
          <option value={'rog'} disabled={forAllImages}>ROG</option>
          <option value={'recency'}>Recency</option>
          <option value={'success'} disabled={forAllImages}>Success score</option>
        </select>
        <select className='px-2 rounded  border-slate-300 border m-1' value={searchParams.get('tab')} onChange={(e) => {
          setSearchParams(prev => {
            prev.set('tab', e.target.value)
            return prev
          })
        }}>
          <option value={''} disabled>--Image Source--</option>
          <option value={'both'}>Social + Retailer</option>
          <option value={'social'}>Social</option>
          <option value={'retailer'}>Retailer</option>
        </select>
        <div className='relative mx-1'>
        </div>
        {searchParams.get('tab') != 'retailer' ? <>
          <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('geography')} onChange={(e) => {
            setSearchParams(prev => {
              prev.set('geography', e.target.value);
              return prev;
            })
          }}>
            <option value={""} disabled>Social geography</option>
            <option value={"All"}>All geography</option>
            <option value={"Asian"}>Asian</option>
            <option value={"Indian"}>Indian</option>
            <option value={"Western"}>Western</option>
            <option value={"Asian,Western"}>Asian + Western</option>
          </select>
        </> : null}
        <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('time')} onChange={(e) => {
          setSearchParams(prev => {
            prev.set('time', e.target.value);
            return prev;
          })
        }}>
          <option value={""}>All Time</option>
          <option value={"7 days"}>7 days</option>
          <option value={"15 days"}>15 days</option>
          <option value={"30 days"}>30 days</option>
          <option value={"45 days"}>45 days</option>
          <option value={"60 days"}>60 days</option>
          <option value={"90 days"}>90 days</option>
          <option value={"180 days"}>180 days</option>
        </select>
        {searchParams.get('tab') != 'social' ? <>
          <div className=' mx-1'>
            <Select
              key={'insertDate' + window.location.href}
              placeholder={'Retailer Insert Date'}
              options={[{ insertDate: 'All', label: 'All', count: insertDatesOptions.reduce((prev, currentElem) => (prev + currentElem.count), 0) }, ...insertDatesOptions]}
              valueField={'insertDate'}
              labelField={'label'}
              multi={true}
              values={insertDatesOptions.filter((obj) => ((searchParams.get('insertDate') || '').split(',,').includes(obj.insertDate)))}
              onChange={(values) => setSearchParams(prev => {
                let next;
                if (values.length == 0 || values[values.length - 1].insertDate == 'All' || values.length == insertDatesOptions.length) {
                  next = ''
                } else {
                  next = values.map(p => p.insertDate).join(',,')
                }
                prev.set('insertDate', next)
                return prev;
              })}
              searchable={false}
              className='px-2 rounded py-1 border-slate-300 border m-1 bg-slate-200 mr-2 w-80 flex flex-wrap'
              wrapperClassName='m-2 w-40'
            />
          </div>
          <div className=' mx-1'>
            <Select
              key={'retailer' + window.location.href}
              placeholder={'Retailer'}
              options={[{ retailer: 'All', label: 'All', count: insertDatesOptions.reduce((prev, currentElem) => (prev + currentElem.count), 0) }, ...retailerOptions]}
              valueField={'retailer'}
              labelField={'label'}
              multi={true}
              values={retailerOptions.filter((obj) => ((searchParams.get('retailer') || '').split(',,').includes(obj.retailer)))}
              onChange={(values) => setSearchParams(prev => {
                let next;
                if (values.length == 0 || values[values.length - 1].retailer == 'All' || values.length == retailerOptions.length) {
                  next = ''
                } else {
                  next = values.map(p => p.retailer).join(',,')
                }
                prev.set('retailer', next)
                return prev;
              })}
              searchable={false}
              className='px-2 rounded py-1 border-slate-300 border m-1 bg-slate-200 mr-2 w-80 flex flex-wrap'
              wrapperClassName='m-2 w-40'
            />
          </div>
        </> : null}
      </div>
      <div>
        <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 mr-2 rounded-3xl' disabled={parseInt(searchParams.get('page') || '1') <= 1}
          onClick={() => {
            setSearchParams(prev => {
              prev.set('page', parseInt(prev.get('page') || '1') - 1);
              return prev
            })
          }}>prev</button>
        <span className='mr-2'>{searchParams.get('page') || 1}/{maxPage}</span>
        <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 mr-2 rounded-3xl' disabled={parseInt(searchParams.get('page') || '1') >= maxPage}
          onClick={() => {
            setSearchParams(prev => {
              prev.set('page', parseInt(prev.get('page') || '1') + 1);
              return prev
            })
          }}>next</button>
      </div>


    </div>
    <div>
      <div className='flex flex-wrap px-4 text-xs'>
        <div className='relative mx-0.5 my-0.5' >
          {searchParams.get('category') && searchParams.get('category') != 'All' ? <Select
            key={'silhouette' + window.location.href}
            placeholder={'SILHOUETTE'}
            options={[{ silhouette: 'All', label: 'All', count: SilhouetteOptions.reduce((prev, currentElem) => (prev + currentElem.count), 0) }, ...SilhouetteOptions]}
            valueField={'silhouette'}
            labelField={'label'}
            multi={true}
            values={SilhouetteOptions.filter((obj) => ((searchParams.get('silhouette') || '').split(',,').includes(obj.silhouette)))}
            onChange={(values) => setSearchParams(prev => {
              let next;
              if (values.length == 0 || values[values.length - 1].silhouette == 'All' || values.length == SilhouetteOptions.length) {
                next = ''
              } else {
                next = values.map(p => p.silhouette).join(',,')
              }
              prev.set('silhouette', next)
              return prev;
            })}
            searchable={false}
            className='px-1 rounded py-0.5 border-slate-300 border  bg-slate-200 mr-0.5  flex flex-wrap text-black w-80'

            wrapperClassName='m-2 w-40 text-black'
          />
            : null}
        </div>

        {searchParams.get('gender') == 'M' && !["Ethinic Wear", "Footwear", "Bags", "Sunglasses", "Caps & Hats", "Belt"].includes(searchParams.get('category')) ? <div className=' mx-1'>
          <Select
            key={'material' + window.location.href}
            placeholder={'MATERIAL (RETAILER ONLY)'}
            options={[{ material: 'All', label: 'All' }, ...materialOptions]}
            valueField={'material'}
            labelField={'label'}
            multi={true}
            values={materialOptions.filter((obj) => ((searchParams.get('material') || '').split(',,').includes(obj.material)))}
            onChange={(values) => setSearchParams(prev => {
              let next;
              if (values.length == 0 || values[values.length - 1].material == 'All' || values.length == materialOptions.length) {
                next = ''
              } else {
                next = values.map(p => p.material).join(',,')
              }
              prev.set('material', next)
              return prev;
            })}
            searchable={false}
            className='px-2 rounded py-1 border-slate-300 border m-1 bg-slate-200 mr-2 w-80 flex flex-wrap'
            wrapperClassName='m-2 w-40'
          />
        </div>
          : null}
        {headers
          .filter(t => (searchParams.get('category') != 'Ethinic Wear' || ['COLOR', 'ETHINIC WEAR', 'KURTA SILHOUETTE', 'NECKLINE'].includes(t.tag)))
          .sort((a, b) => {
            return HeadersRanking[searchParams.get('category')] ? HeadersRanking[searchParams.get('category')][a.tag] - HeadersRanking[searchParams.get('category')][b.tag] : 1
          }).map(header => <div className='relative mx-0.5 my-0.5' key={header.tag}>
            <Select
              key={header.tag + window.location.href}
              placeholder={header.tag}
              options={header.values.map(item => ({ value: item.value, label: `${item.value}(${searchParams.get('tab') != 'retailer' ? item.socialCount : ''}${searchParams.get('tab') == 'both' ? ' and ' : ''}${searchParams.get('tab') != 'social' ? item.retailerCount : ''})` }))}
              valueField={'value'}
              labelField={'label'}
              multi={true}
              values={searchParams.get(header.tag) ?
                header.values.map(item => ({ value: item.value, label: `${item.value}(${searchParams.get('tab') != 'retailer' ? item.socialCount : ''}${searchParams.get('tab') == 'both' ? ',' : ''}${searchParams.get('tab') != 'social' ? item.retailerCount : ''})` })).filter(t => (searchParams.get(header.tag).split(',,').includes(t.value)))
                : []}
              onChange={(values) => setSearchParams(prev => {
                if (values.length == 0) {
                  prev.delete(header.tag)
                } else {
                  prev.set(header.tag, values.map(v => v.value).join(',,'))
                }
                return prev;
              })}
              searchable={false}
              className='px-1 rounded py-0.5 border-slate-300 border  bg-slate-200 mr-0.5  flex flex-wrap text-black w-80'

              wrapperClassName='m-2 w-40 text-black'
            />
          </div>)}
      </div>
    </div>



    <Loadingwrapper loading={loading}>
      <div className='flex w-screen'>
        {
          searchParams.get('tab') != 'retailer' ? <div className='w-full'>
            <div className='m-2 justify-center flex'><img src='/social-logo.png' className='h-5' /></div>
            <div className='flex w-full flex-wrap justify-center'>
              {
                socialImages.map(image => <DesignRecipeImage image={image} type={'social'} isBoth={searchParams.get('tab') == 'both'} allCollectionNames={allCollectionNames} addImageToCollection={socialAddImageToCollectionWrapper} collectionSets={socialcollectionSets} fetchCollections={getCollectionNames} gender={searchParams.get('gender')} category={searchParams.get('category')} />)
              }
            </div>

          </div> : null
        }
        {searchParams.get('tab') == 'both' ? <div className='w-0 border border-black'></div> : null}
        {
          searchParams.get('tab') != 'social' ? <div className='w-full'>
            <div className='m-2 justify-center flex'><img src='/retailer-logo.png' className='h-5' /></div>
            <div className='flex w-full flex-wrap justify-center'>
              {
                retailerImages.map(image => <DesignRecipeImage image={image} type={'retailer'} isBoth={searchParams.get('tab') == 'both'} allCollectionNames={allCollectionNames} addImageToCollection={retailerAddImageToCollectionWrapper} collectionSets={retailercollectionSets} fetchCollections={getCollectionNames} gender={searchParams.get('gender')} category={searchParams.get('category')} />)
              }
            </div>

          </div> : null
        }

      </div>
    </Loadingwrapper>
    {loading ? <p className='text-center text-green-600 font-semibold text-sm mt-10'>Computing, Ranking...{searchParams.get('category') == 'Dresses' ? '(E.T. 2 minutes) ' : ''}Please wait</p> : null}
  </div >


}
export default DesignRecipeImages
