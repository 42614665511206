import { TailSpin } from "react-loader-spinner";

const Loadingwrapper = ({ children, loading, className, textToShow }) => {

  return loading ? <div className={`w-full flex flex-col justify-center items-center ${className}`}>
    <TailSpin />
    {textToShow ? <p className="font-semibold">{textToShow}</p> : null}
  </div> : children
}

export default Loadingwrapper;
