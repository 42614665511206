import moment from "moment"
import ModalImage from "react-modal-image";
import { AiTwotoneHeart } from "react-icons/ai"
import ImageTagsDetails from "../SocialTrendsTags/ImageTagsDetails"
import Collection from "../V2Leaderboard/Collection";

const NewsFeedImage = ({ image, allCollectionNames, addImageToCollection, collectionSets, fetchCollections, trendType }) => {
  return <div className='mr-2 max-[500px]:mr-1 max-w-full min-w-max text-center'>
    <div className='flex  flex-col items-end min-h-60' style={{ minHeight: '17.5rem' }}>
      <div className="relative w-full" data-tooltip-id={'trends-tooltip-' + (trendType == 'social' ? image.mediaId : image.pk)}>
        <Collection image={image} collections={allCollectionNames} addImageToCollection={addImageToCollection} collectionSets={collectionSets} fetchCollections={fetchCollections} />
        <ModalImage src={image.imageUrl} className=' h-[17rem] my-1' small={image.imageUrl} large={image.imageUrl} hideZoom={false} hideDownload={true} loading="lazy" />
      </div>
    </div>
    {<a className='mx-2 flex justify-evenly items-center m-1 font-bold hover:extrabold hover:underline-offset-8 hover:cursor-pointer' href={image.postUrl} target="_blank" rel="noopener noreferrer">
      <div>
        <div className='flex items-center'>{image.hasOwnProperty('countOfLikes') && parseInt(image.countOfLikes) > 3 ? <>{image.countOfLikes}<span className=' text-red-600 text-xl'><AiTwotoneHeart /></span></> : null}</div>
      </div>
      <div>
        <div className='flex items-center'>{image.username}<span className='text-xl'></span></div>
      </div>
      <div>
        {/* <div className='flex items-center'>{image.noOfComments}<span className='text-xl'><BiCommentDots /></span></div> */}
      </div>
    </a>
    }
    <p hidden={trendType == 'retailer'}>{moment(trendType == 'social' ? image.timeStamp : image.insertDate).format("DD MMM YYYY")}</p>
    <ImageTagsDetails mediaId={image.mediaId} isretailer={trendType == 'retailer'} />
  </div >

}
export default NewsFeedImage
