import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { MenCategoryThumbnails, WomenCategoryThumbnails } from "../../utility/CategoryThumbnails"
import { CategoriesSortHomePage } from "../../constants"
import axios, { isAxiosError } from "axios"
import Loadingwrapper from "../../component/LoadingWrapper"
import NewsFeedTrend from "./Trend"
import useCollectionNames from "../../hooks/useCollectionNames"
import useAddImageToCollection from "../../hooks/useAddImageToCollection"
import DepthToolTip from "../../component/DepthTooltip"
import TrendTooltip from "../../component/TrendsTooltip"

const NewsFeed = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(0)
  const [trends, setTrends] = useState([])
  const [allCollectionNames, socialcollectionSets, retailercollectionSets, getCollectionNames] = useCollectionNames()
  const [page, setPage] = useState(1);
  const addImageToCollection = useAddImageToCollection()
  const limit = 25;


  const socialAddImageToCollectionWrapper = (image, collectionId) => {
    addImageToCollection(image._id, collectionId, socialcollectionSets, 'social', getCollectionNames);
  }

  const retailerAddImageToCollectionWrapper = (image, collectionId) => {
    addImageToCollection(image._id, collectionId, retailercollectionSets, 'retailer', getCollectionNames);
  }

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('gender', prev.get('gender') || 'F')
      prev.set('category', prev.get('category') || '')
      prev.set('sort', prev.get('sort') || 'rog')
      prev.set('geography', prev.get('geography') || 'All')
      return prev;
    })
  }, [])
  const getTrends = async (signal) => {
    setLoading(true)
    try {
      const response = await axios.get(`/api/news-feed/trends?gender=${searchParams.get('gender')}&category=${encodeURIComponent(searchParams.get('category'))}&page=${page}&geography=${searchParams.get('geography')}&sort=${searchParams.get('sort')}`, { signal })
      setLoading(false)
      setCount(response.data.count)
      setTrends(prev => {
        const next = [...prev, ...response.data.trends]
        return next
      })
    } catch (e) {
      if (!isAxiosError(e)) {
        console.error(e)
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    setLoading(true)
    const abortController = new AbortController()
    const signal = abortController.signal

    getTrends(signal)

    return () => {
      abortController.abort()
    }
  }, [searchParams, page])

  useEffect(() => {
    setTrends([])
    setPage(1)
  }, [searchParams])

  return <div>
    <h2 className="text-center font-semibold text-2xl p-2">NEWS FEED {searchParams.get('gender') == 'M' ? 'Men' : 'Women'}'s {searchParams.get('category')}</h2>
    <div className="sticky top-10 z-10 bg-white" >
      <div className="flex justify-between">

        <div className="w-full flex p-2 px-4">

          {false && <div className="flex mx-2 pt-2 text-lg" >
            <div
              className="mx-1"
              onClick={() => {
                setSearchParams(prev => {
                  if (prev.get('gender') == 'F') return prev;
                  prev.set('category', '')
                  prev.set('gender', 'F');
                  return prev
                })
              }}>
              <input type="radio" name="gender" value="F" checked={searchParams.get('gender') == 'F'} />
              <label htmlFor="F" className="pl-1 pr-2">Female</label>
            </div>
            <div
              className="mx-1"
              onClick={() => {
                setSearchParams(prev => {
                  if (prev.get('gender') == 'M') return prev;
                  prev.set('category', '')
                  prev.set('gender', 'M');
                  return prev
                })
              }}>
              <input type="radio" name="gender" value="M" checked={searchParams.get('gender') == 'M'} />
              <label htmlFor="M" className="pl-1 pr-2">Male</label>
            </div>
          </div>
          }
          {false && <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('category')} onChange={(e) => {
            setSearchParams(prev => {
              prev.set('category', e.target.value);
              return prev;
            })
          }}>
            <option value={"select cat"} disabled>--Category--</option>
            <option value={""} >All Categories</option>
            {
              Object.keys(searchParams.get('gender') == 'F' ? WomenCategoryThumbnails : MenCategoryThumbnails)
                .sort((a, b) => (CategoriesSortHomePage.indexOf(a) - CategoriesSortHomePage.indexOf(b)))
                .filter(cat => !["Ethinic Wear", "Jumpsuits", "Other Outerwear"].includes(cat))
                .map(cat => <option value={cat} key={cat}>{cat}</option>)
            }
          </select>
          }
          {searchParams.get('gender') != 'M' && <div className="flex mx-2 pt-2 text-lg"          >
            <div>Sort For :</div>
            <div
              className="mx-1"
              onClick={() => {
                setSearchParams(prev => {
                  if (prev.get('sort') == 'rog') return prev;
                  prev.set('sort', 'rog');
                  return prev
                })
              }}>
              <input type="radio" name="sort" value="rog" checked={searchParams.get('sort') == 'rog'} />
              <label htmlFor="rog" className="pl-1 pr-2">Gen Z</label>
            </div>
            <div
              className="mx-1"
              onClick={() => {
                setSearchParams(prev => {
                  if (prev.get('sort') == 'average') return prev;
                  prev.set('sort', 'average');
                  return prev
                })
              }}>
              <input type="radio" name="sort" value="average" checked={searchParams.get('sort') == 'average'} />
              <label htmlFor="average" className="pl-1 pr-2">Millenial</label>
            </div>
          </div>}
        </div>
        <div className="flex pt-4">
          <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('geography')} onChange={(e) => {
            setSearchParams(prev => {
              prev.set('geography', e.target.value);
              return prev;
            })
          }}
            hidden={searchParams.get('gender') == 'M'}
          >
            <option value={""} disabled>--Geography--</option>
            <option value={"All"}>All Geography</option>
            <option value={"asian"}>Asian</option>
            <option value={"indian"}>Indian</option>
            <option value={"western"}>Western</option>
          </select>
          <p className="ml-6 mr-2">Showing 1-{trends.length} of {count}</p>
        </div>
      </div>
    </div>

    {
      trends.map((trend, index) => <>
        <DepthToolTip trend={trend} level={0} category={searchParams.get('category')} />
        {false ? trend.images.map(image => <TrendTooltip image={image} trendType="social" key={image.mediaId} category={searchParams.get('category')} />) : null}
      </>)
    }
    <div className="p-2">
      {
        trends.map((trend, i) => <NewsFeedTrend trend={trend} rank={i + 1} allCollectionNames={allCollectionNames} addImageToCollection={socialAddImageToCollectionWrapper} retailerAddImageToCollection={retailerAddImageToCollectionWrapper} collectionSets={socialcollectionSets} retailercollectionSets={retailercollectionSets} fetchCollections={getCollectionNames} trendType={'social'} gender={searchParams.get('gender')} category={searchParams.get('category')} />)
      }
    </div>

    <Loadingwrapper loading={loading} className="mb-10" textToShow={'Building feed please wait…'}>

      <div className="flex justify-center">


        <button className="primary-btn mb-6 " onClick={() => setPage(prev => prev + 1)}
          hidden={trends.length >= count}
        >Load More</button>
      </div>
      {
        trends.length == 0 ? <div className="p-4 flex justify-center items-center">
          <p className="m-2">Checkout: </p>
          <a
            href={`/v2-leaderboard?gender=${searchParams.get('M') ? 'Male' : 'Female'}&category=${searchParams.get('category')}&time=15+days`}
            className="primary-btn m-2"
          >Trend Leaderboard</a>
          <a
            href={`/design-recipe-images?gender=${searchParams.get('gender')}&category=${searchParams.get('category')}&time=15+days`}
            className="primary-btn m-2"
          >Product Leaderboard</a>
        </div> : null
      }
      <p className="my-6 text-center" hidden={trends.length < count}>End Of results</p>
    </Loadingwrapper>

  </div>
}

export default NewsFeed
